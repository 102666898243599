import React from 'react'
import HeadNav from './layout/Header'
import Footer from './layout/Footer'
import { Container } from '@mui/material'

const TermsAndConation = () => {
  return (
    <div>
      <HeadNav />
      <Container maxWidth="xl">
        <div className="" style={{ marginTop: '120px' }}>
          <h2>Terms of Use</h2>
          <p className="font-16 fw-normal color5f">
            By accessing the Luxury Life website (the “Website” or “Luxury Life”), including through a mobile application, you (the “User,” “Member,” or “You”) agree to be bound by this Terms of Use agreement (the “Agreement”) and the Privacy Policy, which is available here. You agree that you are bound by this Agreement and the Privacy Policy, whether or not You register as a Member of the Website. The Website is operated by Luxury Life Inc. (“Company”). If You wish to visit the Website or to become a Member and make use of the Luxury Life service (the “Service”), please read this Agreement carefully. You are required to accept this Agreement to access and use the Website. This Agreement is in English. You should not rely on any non-English translation of this Agreement. Refer to this Agreement in English in the event of any discrepancies or inconsistencies. The summaries of the Agreement’s provisions contained herein are solely for informational purposes. These summaries are not formally part of the Agreement and do not have binding legal effect.

            Summary: This Agreement is between You and Luxury Life Inc. and is required before You can use the site and the Service. English is the official language of this Agreement. This summary and the other summaries are to help you understand the Agreement and aren’t part of the Agreement itself.

            IF YOU DO NOT ACCEPT AND AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT OR YOU DO NOT MEET OR COMPLY WITH ITS PROVISIONS, DO NOT USE THE WEBSITE OR THE SERVICE.

            Summary: These are our terms, and if You use the Website or our Service, You are bound by this Agreement. Please read this Agreement.

            Please review this Agreement carefully, including the Acceptable Website Use provision, which PROHIBITS ANY UNLAWFUL USE OF THE SITE, INCLUDING ESCORTING, PROSTITUTION, AND HUMAN TRAFFICKING. To proceed with this Agreement and to access the Service of the Website, You must acknowledge and agree to be bound by the terms of this Agreement, including the Acceptable Website Use provision described herein.

            Summary: If You access our Website and/or use our Service, You must do so lawfully. You cannot access our Service without agreeing to these terms.

            BINDING AGREEMENT

            1.1 This Agreement is an electronic contract that sets out the legally binding terms. You must accept this Agreement to use the Website and to become a “Member.” For purposes of this Agreement, the term “Member” or “Membership” means a person who provides information to the Company on the Website to participate in the Service in any manner, whether such person uses the Service as a free Member or as a paid subscriber. This Agreement may be modified by Luxury Life Inc. in its sole discretion at any time, and any such modifications will be posted on the Website. Your continued use of the Website or the Service after the posting of modifications to this Agreement will constitute your acceptance of such revisions.

            Summary: This is a contract between You and Luxury Life Inc. We can modify this Agreement at any time. If You continue to use the Website or the Service after we post modifications to this Agreement, You have accepted the new modified Agreement.

            1.2 This Agreement includes Luxury Life Inc.’s Acceptable Website Use Policy (as set forth herein), Privacy Policy, notices regarding the Website, and terms disclosed and agreed to by You if You become a Member or if You purchase or accept additional features, products, or services we offer on the Website, such as state-specific terms and terms governing features, billing, free trials, discounts, and promotions.

            Summary: Our Acceptable Use Policy and Privacy Policy are incorporated into this Agreement. Any other notices and terms presented to You and accepted by You (either directly or by use of the Service) are also incorporated into these terms.

            ELIGIBILITY

            You must be at least eighteen years old to register as a Member or use the Website. By using the Website, You represent and warrant that You have the right, authority, and capacity to enter into this Agreement and to abide by all of the terms and conditions of this Agreement. If You become a Member, You represent and warrant that You have never been convicted of a felony and that You are not required to register as a sex offender (or similar designation) with any government entity or legal authority. You will only use the Service and the Website in a manner consistent with this Agreement and any and all applicable local, state, national, and international laws and regulations, including, but not limited to, export control laws. By using the Service, You represent that Any government has not designated you as a “Specially Designated National” or other person to whom the provisions of the Service are prohibited. Registration for and use of the Service is void where prohibited. You are responsible for determining whether the use of the Service is legal in the jurisdiction in which you access the Website.

            Summary: You must be 18 years old or older to use the Website. As a Member, You are telling us that You have never been convicted of a felony and that You are not required to register as a sex offender or any similar designation with any governmental agency. You will only use the Service as per these terms and all applicable laws. You are responsible for determining whether You can legally use the Service.

            TERM AND TERMINATION

            All terms of this Agreement will remain in full force and effect while You use the Website and/or are a Member. Moreover, all terms of this Agreement will survive the Agreement’s termination by either You or Luxury Life Inc., except for any terms that by their nature expire or are fully satisfied. You may terminate your Membership at any time and for any reason by following the instructions in the Deactivate Account section in Account Settings or by sending a written notice of termination to Luxury Life Inc. If you deactivate your account, Luxury Life Inc. may still retain certain information associated with your account for analytical purposes and record-keeping integrity, as well as to prevent fraud, collect any fees owed, enforce this Agreement and its conditions, take actions it deems necessary to protect the integrity of the Website or to protect the Website’s users, or take other actions otherwise permitted by law. In addition, if certain information has already been provided to third parties as described in the Privacy Policy, retention of that information will be subject to those third parties’ policies.

            Luxury Life Inc. may terminate your Membership for any reason, with or without notice. If your Membership is terminated—whether by You or Luxury Life Inc.—You will not be entitled to any refund of unused subscription fees or unused credits.

            Summary: If You continue to use the Service, these terms are still applicable. You can terminate your Membership whenever You want. We can terminate your Membership at any time and for any reason. We won’t issue a refund if we think You failed to abide by these terms. These terms are still applicable after the termination of your Membership.

            EXCLUSIVELY FOR NON-COMMERCIAL USE

            The Website and the Service are for personal use only. Any other use, including use by the media, competitors, or any entity or individual wishing to use the Website or Service for profit, is considered “Commercial Use” by “Commercial Users.” Prohibited Commercial Use includes, but is not limited to, using the Service in connection with any commercial endeavors such as:
            (i) advertising or soliciting any user to buy or sell any products or services not offered by the Company;
            (ii) soliciting others to attend parties or other social functions or networking for commercial purposes;
            (iii) press or media purposes of any kind not authorized by the Company;
            (iv) seeking leads or customer information for any kind of business activities; or
            (v) scraping or otherwise collecting data or information of any kind. Organizations, companies, agencies, and/or businesses—and any other associations or entities that are not individual persons—may not become Members and should not use the Service or the Website for any purpose. Illegal and/or unauthorized uses of the Website, including collecting usernames and/or email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorized framing of or linking to the Website, may be investigated, and appropriate legal action may be taken. Such action may include (without limitation) civil, criminal, and injunctive relief. Users of the Website may not use any information obtained from the Service to contact any other user without the other user’s prior explicit consent. The Company may investigate and take any available legal action in response to illegal and/or unauthorized uses of the Website, including referring activities the Company deems suspicious to governmental authorities.

            Summary: You CANNOT use the Website or the Service for business or media purposes. Illegal and/or unauthorized use of the Website or Service will be investigated, and we will take legal action. We may refer activities we deem suspicious to governmental authorities.

            ACCOUNT SECURITY

            Your Membership account is for your personal use only. You are responsible for maintaining the confidentiality of your username and password, and You are solely responsible for all activities that occur under your username and password, including the distribution of your personal information to other users. You acknowledge that Luxury Life Inc. is not responsible for third-party access to your account information or personal information when that access results from any action or inaction on your part, including but not limited to the voluntary distribution of your username, password, or other personal information. You also acknowledge that Luxury Life Inc. is not responsible for third-party access to your account information when that access results from theft or misappropriation of your username, password, or other personal information from your possession, custody, or control.

            Summary: Keep your username and password and personal information confidential. You are responsible for anything that occurs on the Website under your username and password, including providing personal information to others and keeping your information secure on your end.

            RISK ASSUMPTION AND PRECAUTIONS

            6.1 LUXURY LIFE INC. IS NOT RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE WEBSITE OR MEMBER OF THE SERVICE. YOU ASSUME ALL RISKS WHEN USING THE SERVICE AND THE WEBSITE, INCLUDING, BUT NOT LIMITED TO, ALL OF THE RISKS ASSOCIATED WITH ANY ONLINE OR OFFLINE INTERACTIONS WITH OTHER MEMBERS OR WEBSITE USERS, INCLUDING DATING OR SENDING MONEY TO ANOTHER MEMBER. YOU AGREE TO TAKE ALL NECESSARY PRECAUTIONS WHEN MEETING INDIVIDUALS THROUGH THE WEBSITE OR SERVICE. You should not provide your financial information (for example, your credit card or bank account information) to other Members.
            6.2 LUXURY LIFE INC. DOES NOT CURRENTLY CONDUCT CRIMINAL BACKGROUND SCREENINGS ON ITS MEMBERS. However, Luxury Life Inc. reserves the right to conduct any criminal background check at any time and using any records legally available. BY AGREEING TO THESE TERMS AND CONDITIONS, YOU HEREBY AUTHORIZE ANY SUCH CHECK.
            6.3 Members may have the option to pay a third party to perform a self-background screening based on information the Member provides to the third party. Members who pass such a self-background screening may have a “Background Verified” mark displayed on their profile. You understand that Luxury Life Inc. makes no guarantees, express or implied, regarding the accuracy or reliability of the third-party self-background screening or “Background Verified” mark, and in no event will Luxury Life Inc. be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the self-background screening or “Background Verified” mark.
            6.4 You understand that Luxury Life Inc. makes no guarantees, either express or implied, regarding your ultimate compatibility with individuals You meet through the Service or as to the conduct of such individuals.

            Summary: We aren’t responsible for our users’ conduct online or offline. We don’t do criminal background checks on all of our Members, but we may conduct such a check at our discretion, and by agreeing to these terms, You agree that we can do such a check. You can pay for a self-background screening, but we make no guarantees about the reliability of the “Background Verified” mark as it relates to You or to other users/Members who paid for a similar screening. You assume these responsibilities when using the Service. Please take appropriate precautions.

            PROPRIETARY RIGHTS

            Luxury Life (“[Luxury Life Inc.]”) retains all proprietary rights in the Website and the Service and in all content, trademarks, trade names, service marks, and other intellectual property rights related thereto. The Website contains the copyrighted material, trademarks, and other proprietary information of Luxury Life Inc. Except for information that is in the public domain or which You have been given written permission to use, You may not copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary information. You agree to not remove, obscure, or otherwise alter any proprietary notices appearing on any content, including copyright, trademark, and other intellectual property notices.

            Summary: We own the Website and the Service, which includes all the content, trademarks, trade names, service marks, and other intellectual property. Don’t copy or alter our stuff without our written permission.

            ACCEPTABLE WEBSITE USE

            8.1 You are solely responsible for the content and information that You post, upload, publish, link to, transmit, record, display, or otherwise make available (hereinafter, “Post” or “Posting”) on the Website or as part of the Service or transmit to other Members, including emails, videos (including streaming videos), photographs, voice notes, recordings or profile text, whether publicly posted or privately transmitted (collectively, “Content”). You may not Post on the Website or as part of the Service, or transmit to the Website or any other Member (either on or off the Website) any material or engage in conduct on or through the Website:
            (i) in violation of any applicable law or regulation;
            (ii) in a manner that will infringe the copyright, trademark, trade secret, or other intellectual property rights of others or violate the privacy, publicity, or other personal rights of others;
            (iii) in a manner that is offensive, inaccurate, profane, intimidating, defamatory, obscene, threatening, abusive, or hateful;
            (iv) that uses multiple accounts for one user; or
            (v) that expresses or implies that any statements You make are endorsed by Luxury Life Inc., the Website. You represent and warrant that all information that You submit upon registration is accurate and truthful and that You will promptly update any information provided by You that subsequently becomes inaccurate, misleading, or false.

            Summary: You are responsible for what You do on our Website and how You use our Service. Don’t post or transmit any material that violates laws or regulations. Don’t post or transmit any material in a manner that will infringe on ours or others’ copyright, trademark, and other intellectual property rights. Don’t be rude, and don’t threaten or offend others.

            8.2 Luxury Life Inc. reserves the right but has no obligation, to monitor any Content You post on the Website or as part of the Service. Luxury Life Inc. may remove any Content for any reason whatsoever. Without limitation, Luxury Life Inc. may remove Content that, in its sole opinion, violates, or may violate any applicable law or the letter or spirit of this Agreement. Luxury Life Inc. may also remove any Content upon the request of any third party or for any other reason whatsoever. Luxury Life Inc., in its sole discretion, may suspend or ban any Member that posts Content that is objectionable for any of the reasons given in this paragraph or for any other reason.

            Summary: We don’t have to, but we may monitor Content posted on the Website. We also may remove any Content that we think may violate the law or this Agreement or upon request by others and we may remove the Member posting such Content as well.

            8.3 By Posting Content on the Website or as part of the Service, You automatically grant to Luxury Life Inc. an irrevocable, perpetual, non-exclusive, fully paid-up, worldwide right and license to:
            (i) use, copy, store, perform, display, reproduce, record, play, adapt, modify and distribute the Content;
            (ii) prepare derivative works of the Content or incorporate the Content into other works; and
            (iii) grant and authorize sublicenses of the foregoing in any media now known or hereafter created. In addition, so that Luxury Life Inc. can prevent the use of your Content outside of the Service, You authorize Luxury Life Inc. to act on your behalf with respect to infringing uses of your Content taken from the Service by other users or third parties. Our license to your Content is subject to your rights under applicable law (including without limitation laws regarding personal data protection to the extent any Content contains personal information as defined by those laws) and is for the limited purpose of operating, developing, providing, and improving the Service and researching and developing new ones.

            Summary: Once You post something, we may use it in accordance with the terms of this Agreement.

            8.4 You are prohibited from violating or attempting to violate the security of the Website, including, without limitation:
            (i) accessing data not intended for You or logging into a server or account that You are not authorized to access;
            (ii) attempting to probe, scan, or test the vulnerability of a system or network or breaching (or attempting to breach) security or authentication measures without proper authorization;
            (iii) attempting to interfere with or disrupt the Service or the Website, including, without limitation, by way of submitting a virus to the Website, overloading, flooding, spamming, mail bombing, or crashing;
            (iv) sending unsolicited email, including promotions and/or advertising of products or services;
            (v) forging headers or otherwise manipulating identifiers in order to disguise the origin of any information transmitted to or through the Website or Service (either directly or indirectly through the use of third-party software);
            (vi) using (or attempting to use) any engine, software tool, agent, or other device or mechanism, any robot, spider, site search/retrieval application, or any manual or automatic device or process to retrieve, index, “data mine” or in any way reproduce or circumvent the navigational structure or presentation of the Website, Service or its Contents; or
            (vii) modifying, adapting, sublicensing, translating, selling, reverse engineering, deciphering, decompiling, or otherwise disassembling any portion of the Service or the Website, or any software used on or for the Service or the Website, or causing others to do so.

            Summary: Don’t do things to or on the Website that You aren’t authorized to do.

            8.5 The Website may be used only for lawful purposes by individuals seeking relationships. As one of the conditions of your use of the Website and the Service, You represent, warrant, and agree that You will not use (or plan, encourage, or help others to use) the Website or the Service for any purpose or in any manner that is prohibited by this Agreement or by applicable law. It is your responsibility to ensure that your use of the Website and the Service complies with this Agreement and to seek prior written consent from Luxury Life Inc. for any uses not permitted or not expressly specified herein. Luxury Life Inc. specifically prohibits any use of the Website or the Service, and You hereby agree not to use the Website or the Service for any of the following:

            (i) Using the Website as an escort or prostitute or using the Service to promote, solicit, or engage clients for an escort or prostitution service, or to engage or facilitate human trafficking of any kind, including past escort activities or affiliation with an escort site or service;

            (ii) Posting or sending material that exploits people under the age of 18, soliciting personal information from anyone under 18, failing to report knowledge of a person under the age of 18 to support@luxurylife.ai, or continuing to use the site to interact in any way with anyone You know or believe is under the age of 18. Any violation of these prohibitions will result in termination of your Membership and possible referral to law enforcement or other agencies, such as the National Center for Missing and Exploited Children.

            (iii) Posting any incomplete, false, misleading, or inaccurate Content about yourself and/or your profile;

            (iv) Posting any Content that is not entirely your own or which You do not have full rights to use;

            (v) Impersonating or otherwise misrepresenting an affiliation, connection, or association with any person or entity;

            (vi) If You have a password, allowing any other person to access a non-public area of the Website, disclosing or sharing your password to or with any third parties or using your password for any unauthorized purpose; using meta tags or code or other devices containing any reference (express or implied) to the Website or the Service (or any trademark, trade name, service mark, logo or slogan of the Website) to direct any person to any other website for any purpose;

            (vii) Soliciting, from other users, passwords or personal identifying information for commercial or unlawful purposes, or for any other reason that would constitute a violation of this Agreement;

            (viii) Engaging in advertising to, or solicitation of, other Members to send money or buy or sell any products or services. You may not transmit any chain letters or junk/spam emails to other Members. If You breach the terms of this subsection and send or post unsolicited bulk email, spam, or other unsolicited communications of any kind through the Service, You acknowledge that You will have caused substantial harm to Luxury Life Inc.;

            (ix) Initiating contact with Members off site by any other means without first obtaining explicit permission from them to do so via the Website;

            (x) Posting advertisements or solicitations of employment, business, or pyramid schemes;

            (xi) Using the Website or Service for activities that violate any law, statute, ordinance, or regulation;

            (xii) Using the Website or Service to encourage, promote, facilitate, or instruct others to engage in illegal activity;

            (xiii) Engage in any activity that violates criminal laws of the jurisdiction in which the Member resides, visits, or contacts Members;

            (xiv) Bullying, stalking, intimidating, or otherwise harassing any other Members or Company employees or representatives;

            (xv) Framing or mirroring any part of the Service or the Website without the Company’s prior written authorization;

            (xvi) Using the Service in order to damage Luxury Life Inc.;

            (xvii) Using the Website for any competitive purpose, including copying, soliciting, competitive or market analysis, or any other use by a competitor;

            (xviii) Recruiting, including for pornography, modeling, or escort sites, or brothels;

            (xix) Commercial Use as defined above, in Section 4;

            (xx) Any other use that violates any applicable law, including without limitation laws related to export controls.

            Summary: You may only use the Website as it is intended. No escorts or prostitutes. No human trafficking of any kind. Report any minors using the Website. Don’t seek out minors, and don’t use the site if You are a minor. Don’t use the Website for unlawful purposes. If You use the Website in a way that is not authorized or illegal, we will take legal action against You and/or refer You to the proper law enforcement authorities.

            8.6 Commercial Use and Competitive Violations and Liquidated Damages. Commercial Users (as defined in Section 4) act in violation of the terms of this Agreement and acknowledge that they are not using the Website for its intended purpose. Each and every instance of Commercial Use or competitive use of the Website is in violation of this Agreement and is subject to a $100,000 award for damages as liquidated damages for each breach. You acknowledge that any Commercial Use of the Website or Service will cause harm in an amount that would be extremely difficult to ascertain and that $100,000 for each and every commercial or competitor violation is a reasonable estimate of such harm.

            Summary: The Website is for personal use only. Each use of the Website for commercial or competitive reasons will result in a $100,000 award for damages.

            HARASSMENT OF LUXURY LIFE INC. EMPLOYEES

            You will not harass, annoy, intimidate, or threaten any Luxury Life Inc. employees or agents engaged in providing any portion of the Service to You. If we feel that your behavior towards any of our employees is at any time threatening or offensive, we reserve the right to immediately terminate your Membership, and You will not be entitled to any refund of unused subscription fees.

            Summary: Don’t harass our employees. We will terminate your Membership if You are threatening or offensive to our employees, and You won’t get a refund.

            COPYRIGHT POLICY; NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT

            You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if You believe that your work has been copied and posted on the Service in a way that constitutes copyright infringement, please contact our Copyright Agent at copyright@luxurylifeinc.com or at the following address:
            Luxury Life Inc., Attn: Copyright Agent, and provide the following information:

            An electronic or physical signature of a person authorized to act on behalf of the copyright owner;
            Identification of the copyrighted work that You claim is being infringed;
            Identification of the material that is claimed to be infringing and where it is located on the Website;
            Information reasonably sufficient to permit Luxury Life Inc. to contact You, such as your address, telephone number, and email address;
            A statement that You have a good faith belief that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;
            A statement, made under penalty of perjury, that You are the copyright owner or authorized to act on the copyright owner’s behalf.

            Summary: Don’t use or post copyrighted, trademarked, or proprietary material unless You have written permission from the owner of such material. If You think someone on the Website has violated your copyright, trademark, or proprietary rights, let us know.

            SUBSCRIPTION AND MEMBERSHIP

            (i) You must provide accurate, current, and complete information for your Billing Account. You must promptly update all information to keep your Billing Account current, complete, and accurate (such as a change in billing address, card number, or expiration date). You must promptly notify luxurylife.ai if your payment method is canceled (including if you lose your card or it is stolen) or if you become aware of a potential breach of security (such as an unauthorized disclosure or use of your username or password). Changes to such information can be made at “Account Settings” on the Website. If you fail to provide us with any of the foregoing information, you agree that you are responsible for fees accrued under your Billing Account. In addition, you authorize us to obtain updated or replacement expiration dates and card numbers for your credit or debit card as provided by your credit or debit card issuer.

            (ii) You agree that all payments made on the Website are final and that you will not challenge or dispute the charge with your bank. You further agree that should you have any issues relating to your payment (such as duplicate billing), you will open a support ticket through the Website to resolve the issue. Should a dispute be initiated with your bank, you agree that you will be held responsible for any outstanding balance owed to us plus any dispute case fees charged by our bank, which may be as much as US$60 per instance. You agree that you will pay any outstanding balance you have with Luxury Life within 30 days from the date of notification. Any outstanding balance left unpaid after 30 days may be submitted to a collections agency, and you agree that a collections fee of up to 50% of the outstanding balance or $100 (whichever is higher) will be added to the amount that you owe.

            (iii) In order to provide continuous service, Luxury Life provides for automatic renewal. Whether it exercises the right or not, Luxury Life has the right to automatically renew your Membership from month to month. UNTIL TERMINATED, LUXURY LIFE MAY AUTOMATICALLY RENEW ALL PAID SUBSCRIPTIONS FOR THE SERVICES ON THE DATE SUCH SUBSCRIPTIONS EXPIRE. After your initial subscription commitment period and again after any subsequent subscription period, your subscription will automatically continue for an additional equivalent period. By entering into this Agreement, you acknowledge that your account will be subject to the above-described automatic renewals. If you do not wish your account to renew automatically or if you want to change or terminate your subscription, please log in and go to “Account Settings” on the Website and follow the directions contained therein.

            (iv) LUXURY LIFE MAINTAINS A STRICT NO-REFUND POLICY. ALL PURCHASES MADE ON THE WEBSITE ARE FINAL. IF YOU CHOOSE TO PURCHASE A SUBSCRIPTION PACKAGE ON THE WEBSITE, YOU AGREE THAT ABSOLUTELY NO REFUNDS, EITHER FULL OR PARTIAL, WILL BE ISSUED FOR ANY REASON OR FOR ANY UNUSED OR REMAINING SUBSCRIPTION OR CREDITS. (Certain exceptions to this policy apply for customers located in the European Union, European Economic Area, and the United Kingdom).

            (v) When you deactivate your Luxury Life account or if your account is suspended for violating this Agreement or other policies, you agree that any remaining or unused subscription and credits will be forfeited, and no refunds will be issued for any remaining or unused Membership subscription and credits. Further, if you're deactivated Luxury Life account is reinstated at a later date, you agree that neither any unused subscription nor credits forfeited at the time of your account deactivation will be reinstated with your account.

            (vi) Credits or a subscription purchased for a specific Luxury Life account are strictly non-transferable. You agree that any remaining or unused Membership subscription associated with an active or deactivated Luxury Life account will not be transferred to any other account belonging to you or to someone else.

            (vii) Luxury Life reserves the right to offer free Membership to any person(s) of its choice at any given time, for any duration, while charging other Members at the same time. Luxury Life also reserves the right to cancel the free trial period at any time for all or any of its Members. A Member profile (whether that profile relates to a paying subscription or a free Membership) may remain posted on the Website even if that Member is not actively using the Service. You acknowledge that although a Member’s profile may be viewed, you may not (even as a subscriber) be able to use the Service to communicate with that Member if he or she is not then actively using the Service.

            Summary: You need to give us accurate information for your billing account and you need to keep the information updated. All payments made are final—no refunds—even if you or we terminate your account early (exceptions for Members located in certain specified geographic locations). Credits or subscriptions can’t be transferred to any other account. If you think there has been an error in billing, you must contact us immediately. Your account may be automatically renewed. We can grant free Memberships to whomever and whenever we want, and we can cancel any free trial periods when we want.

            CANCELATION
            Our cancellation policy allows customers to cancel their orders or subscriptions within a specified time frame. To initiate a cancellation, customers must follow the proper designated cancellation procedure by going to their profile and selecting the billing option. Click on the “Cancel My Subscription” on our website. After this period, the eligibility for refunds or cancellations will not be allowed, except in certain situations, such as instances of defective or damaged products, non-delivery of services, or other cases explicitly outlined in this policy.

            For services already initiated, no refunds may be granted based on the days of service used. Subscriptions canceled mid-cycle will not be refunded for the remaining duration but will stop further automatic renewals.

            Certain services may have specific non-cancellable terms, which will be explicitly mentioned at the time of purchase. We reserve the right to cancel orders for any reason, such as stock unavailability, technical errors, or suspected misuse. In such cases, a full refund will be provided to the customer.

            Summary: Cancellations aren’t allowed once the service has been activated from our end. Other terms vary based on service completion.

            Refund Policy
            Refunds are processed based on the type of service and the specific conditions mentioned in this provision. For eligible cancellations, like billing errors, refunds will be processed within 7-10 business days after approval. Customers must provide accurate bank details or payment information to ensure seamless refunds.

            Non-refundable services or items, including subscriptions beyond the cancellation window, will not qualify for a refund. For defective or damaged products, customers must report the issue within 48 hours of receipt to be eligible for a replacement or refund.

            Any processing fees or third-party charges incurred are non-refundable. Refund disputes should be raised with our customer support team within 15 days of the refund decision.

            Summary: Refunds are processed within 7-10 days for eligible cases, excluding non-refundable items or incurred processing fees.

            SERVICE AND MODIFICATION TO THE WEBSITE

            Luxury Life does not guarantee that the Website will be fully operational all of the time. Members may face significant service disturbances. Only in the event that the Website is offline for a period of more than 72 consecutive hours will a pro-rated refund be given. LUXURY LIFE DOES NOT OFFER TECHNICAL SUPPORT. We will offer support only for the Service related to the Website. The Company reserves the right at any time to modify or discontinue, temporarily or permanently, the Website or the Service (or any part thereof) with or without notice to any Member, group of Members, or all Members. You agree that the Company shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service. To protect the integrity of the Website or the Service, the Company reserves the right at any time, in its sole discretion, to block users related to certain IP addresses from accessing the Website or Service. You agree not to circumvent, or attempt to circumvent, any such user block imposed by the Company.

            Summary: We can’t guarantee that the Website will be operational all the time. We will provide Members with a pro-rated refund only if the Website is offline for more than 72 hours consecutively. We don’t offer technical support. We can modify or discontinue any part of the Website or the Website as a whole at any time without giving you notice, and we are not liable as a result. We can block users at any time from accessing the Website or Service.

            USER INFORMATION

            (i) When you register for our Service on the Website, you will be asked to provide us with certain information, including but not limited to a valid email address (your “User Information”). Luxury Life’s right to use your User Information is described in our Privacy Policy. Please see our Privacy Policy for further details regarding the use of your User Information. Luxury Life reserves the right to offer third-party services and products to you based on the preferences that you identify in your User Information and at any time thereafter; such offers may be made by us or by third parties and are governed by our Privacy Policy.

            (ii) You acknowledge and agree that Luxury Life may disclose in whole or in part your User Information and Content if required to do so by law, at the request of a third party (subject to our Privacy Policy), or if we, in our sole discretion, believe that disclosure is reasonable to:
            [i] comply with the law, requests, or orders from law enforcement or any compulsory legal process;
            [ii] protect or defend Luxury Life, Luxury Life, or a third party’s rights or property; or
            [iii] protect someone’s health or safety, such as when harm or violence against any person (including the user) is threatened.

            (iii) Notwithstanding any other provision of this Agreement or the Privacy Policy, Luxury Life reserves the right, but has no obligation, to disclose any User Information and Content that you submit to the Service if, in its sole opinion, Luxury Life suspects or has reason to suspect that the information involves a party who may seek to commit a crime, be committing a crime, or be the victim of a crime or of any form of abuse. Abuse may include, without limitation, elder abuse, child abuse, spousal abuse, neglect, domestic violence, or human trafficking. Information may be disclosed to authorities that Luxury Life, in its sole discretion, deems appropriate to handle such disclosure. Appropriate authorities may include, without limitation, law enforcement agencies, child protection agencies, or court officials. You hereby acknowledge and agree that Luxury Life is permitted to make such disclosure.

            (iv) User profiles that have been inactive (i.e., users have not logged into their account) for more than 365 days will be removed from the Service, and profile content will be deleted. User account settings and contact emails will continue to be maintained by Luxury Life, subject to Luxury Life’s Privacy Policy.

            Summary: We collect personal information from you and may share it, so you need to read our Privacy Policy.

            LINKS

            The Website may contain, and the Service may provide, links to sites that are not maintained by Luxury Life. While we try to include links only to those sites that are in good taste and safe for our users, we are not responsible for the content or accuracy of those sites and cannot guarantee that sites will not change without our knowledge. The inclusion of a link in the Website does not imply our endorsement of the linked site. If you decide to access linked third-party websites, you do so at your own risk. Your correspondence or business dealings with, or participation in promotions of, third parties found on or through the Website or Service, including payment and delivery of related goods or services, and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such third party. You further acknowledge and agree that Luxury Life and its affiliated companies shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, information, statements, advertising, goods or services, or other materials available on or through any such linked website or resource.

            Summary: There may be links on the Website that we don’t maintain. We aren’t responsible for the content on those sites. Just because a link is on our Website does not mean we are endorsing the linked site. Your interaction with the linked site is at your own risk. We aren’t liable for any damage or loss you may incur by engaging with the linked site.

            DISCLAIMERS

            (i) Luxury Life is not responsible for any incorrect or inaccurate Content posted on the Website or in connection with the Service, whether caused by users of the Website, Members, or by any of the equipment or programming associated with or utilized in the Service.

            (ii) Luxury Life is not responsible for user or Member conduct of any kind, whether online or offline.

            (iii) Luxury Life is not responsible for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, user or Member communications.

            (iv) Luxury Life is not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users and/or Members or to any other person’s computer related to or resulting from participating or downloading materials in connection with the Website and/or in connection with the Service.

            (v) Under no circumstances will Luxury Life be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of the Website or the Service, any Content posted on the Website or transmitted to Members, or any interaction or contact between users of the Website, whether online or offline.

            (vi) THIS SITE IS FOR ENTERTAINMENT AND INFORMATIONAL PURPOSES ONLY. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, LUXURY LIFE PROVIDES THE WEBSITE AND SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND GRANTS NO WARRANTIES OF ANY KIND, EITHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICE OR THE WEBSITE (INCLUDING ALL CONTENT CONTAINED THEREIN) INCLUDING (WITHOUT LIMITATION) ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, EXPECTATIONS OF PRIVACY OR NON-INFRINGEMENT. LUXURY LIFE DOES NOT WARRANT THAT THE WEBSITE OR SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, SECURE OR THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED.

            (vii) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE OR WEBSITE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE OR LOSS OR CORRUPTION OF DATA THAT RESULTS OR MAY RESULT FROM THE DOWNLOAD OF ANY SUCH MATERIAL. IF YOU DO NOT ACCEPT THIS LIMITATION OF LIABILITY, YOU ARE NOT AUTHORIZED TO DOWNLOAD OR OBTAIN ANY MATERIAL THROUGH THE SERVICE OR WEBSITE.

            (viii) From time to time, Luxury Life may make third-party opinions, advice, statements, offers, or other third-party information or content available on the Website and/or through the Service. All third-party content is the responsibility of the respective authors thereof and should not necessarily be relied upon. Such third-party authors are solely responsible for such content. LUXURY LIFE DOES NOT: (I) GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY THIRD-PARTY CONTENT ON THE WEBSITE OR PROVIDED THROUGH THE SERVICE; OR (II) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE OR STATEMENT MADE BY ANY PARTY THAT APPEARS ON THE WEBSITE OR SERVICE. UNDER NO CIRCUMSTANCES WILL LUXURY LIFE BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE RESULTING FROM YOUR RELIANCE ON INFORMATION OR OTHER CONTENT POSTED ON THE WEBSITE OR SERVICE OR TRANSMITTED TO OR BY ANY MEMBERS.

            (ix) Luxury Life cannot guarantee and does not promise any specific results from the use of the Website and/or the Service.

            (x) This Website is only a venue—it acts as a venue for individuals to post personal and contact information for purposes of dating. Luxury Life is not required to screen or censor information posted on the Website, including but not limited to the identity of any user and any user content. We are not involved in any actual communication between Members. As a result, we have no control over the quality, safety, or legality of the information or profiles posted or the truth or accuracy of such information. You agree that You are solely responsible for your interactions with other Members and that Luxury Life is not liable in any way for Member communications. Luxury Life reserves the right, but has no obligation, to monitor communications on the Website between You and other Members or any other correspondence or interaction that occurs outside the scope of the Website. The Website and Service is a tool for providing the initial contact between Members, and anything beyond that is not in our control and is carried out at the Members’ own risk. Members must use common sense about what information they reveal to others via email or any other means of communication. It is your responsibility to ensure your safety and to investigate Members and verify they are legitimate relationship seekers. There are many different frauds, schemes, and deceptions on the Internet, and we strongly caution You to be skeptical of any Member until You learn more about them and verify their background.

            (xi) In addition to the preceding information under this Agreement, any advice or thoughts that may be posted on the Website or through the Service provided for under this Agreement are for informational and entertainment purposes only and are not intended to replace or substitute for, any professional, financial, medical, legal or other advice. The Company makes no representations or warranties and expressly disclaims any and all liability concerning any treatment, action by, or effect on any person following the information offered or provided within or through the Website. If You have specific concerns or a situation arises in which You require professional or medical advice, You should consult with an appropriately trained and qualified specialist and seek immediate attention from the proper professional.

            (xii) You specifically acknowledge and agree that Luxury Life is not liable for your defamatory, offensive, infringing, or illegal materials or conduct or that of third parties, and we reserve the right to remove such materials from the Website without liability or notice to You.

            Summary: We are not responsible or liable for a number of things related to the Website and your use of the Website. You are using the Website and Service at your own risk, and any communications between You and other users/Members are at your own risk. If You read something on our site, don’t take it on its face—use common sense, do your researc,h and take precautions.

            LIMITATION ON LIABILITY

            (i) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LUXURY LIFE BE LIABLE FOR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL OR INDIRECT DAMAGES ARISING OUT OF OR RELATING TO YOUR USE, OR INABILITY TO USE, THE SERVICES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OR CORRUPTION OF DATA OR PROGRAMS, OR FOR SERVICE INTERRUPTIONS OR PROCUREMENT OF SUBSTITUTE SERVICES, EVEN IF LUXURY LIFE KNOWS OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL LUXURY LIFE’S AGGREGATE LIABILITY, IN ANY FORM OF ACTION WHATSOEVER IN CONNECTION WITH THIS AGREEMENT OR THE USE OF THE SERVICE OR THE WEBSITE, EXCEED THE PRICE PAID BY YOU FOR YOUR MEMBERSHIP, OR, IF YOU HAVE NOT PAID LUXURY LIFE FOR THE USE OF ANY SERVICE, THE AMOUNT OF US$25.00 OR ITS EQUIVALENT.

            (ii) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL LUXURY LIFE BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL AND/OR INCIDENTAL ARISING OUT OF, OR RELATING TO, THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SERVICE, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER MEMBERS OF THE SERVICE. THIS INCLUDES ANY CLAIMS, LOSSES, OR DAMAGES ARISING FROM THE CONDUCT OF MEMBERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM YOU.

            (iii) To the fullest extent permitted by applicable law, You release the Company from responsibility, liability, claims, demands, or damages of every kind and nature, known and unknown (including, but not limited to, claims of negligence), arising out of, or related to, disputes between users and the acts or omissions of third parties. You expressly waive any rights You may have under California Civil Code § 1542 as well as any other statute or common law principles that would otherwise limit the coverage of this release to include only those claims which You may know or suspect to exist in your favor at the time of agreeing to this release.

            (iv) THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH RESPECT TO THEIR ESSENTIAL PURPOSE.

            Summary: We have built our Service for You, but we can’t promise it is perfect. We are not liable for various things, and You release us from responsibility and liability for those things.

            LITIGATION OF DISPUTES

            (i) Luxury Life is always interested in attempting to resolve any dispute by amicable and informal means, and we encourage You to contact us at support@luxurylife.ai  before resorting to litigation.

            (ii) However, neither You nor Luxury Life are required to resolve any dispute before bringing that dispute in litigation.

            (iii) Both You and Luxury Life agree that all disputes will be litigated exclusively in the state and federal courts of the United States or India and not in any other forum.

            (iv) By accessing and using the Website and/or Service, You hereby irrevocably consent to the jurisdiction and venue of the federal or state courts located in United States or India.

            (v) YOU AND LUXURY LIFE ACKNOWLEDGE THAT NEITHER OF US WILL BRING OR PARTICIPATE IN ANY CLASS ACTION OR OTHER CLASS PROCEEDING IN CONNECTION WITH ANY DISPUTE WITH THE OTHER PARTY.

            (vi) If You access the Website or use the Service from outside United States or India, You waive all rights under the laws and regulations of the territory from which You access or use the Website or Service.

            (vii) This Agreement, and any dispute between You and Luxury Life, shall be governed by the laws of United States or India without regard to principles of conflicts of law.

            Summary: If You use the Website or Service, You must litigate any disputes in the federal or state courts in the United States or India, depending on the nature of the dispute and its origin. Further, we are based in the United States and India, and You will be governed by and subject to the laws of the United States or India, as applicable.

            CONSENT TO SERVICE OF PROCESS BY EMAIL

            By using the Website or Service, You consent to the service of process of any summons and/or complaint at the email address that you provided to Luxury Life to create your account. You further agree that email is a reasonable means of notice and service for any litigation.

            Summary: By providing your email address when using the Website or Service, You are agreeing to receive any type of litigation notice via that email address.

            CONSENT TO ALLOW Luxury Life Inc. TO BRING LAWSUITS ON YOUR BEHALF.

            You agree that Luxury Life Inc. may bring actions on behalf of You or any other Member or Members in any judicial, arbitral, or other forum where Luxury Life Inc.’s rights may be implicated.

            Summary: You are agreeing to let Luxury Life Inc. bring legal and equitable actions on your behalf if Luxury Life Inc. determines its own rights are implicated in those actions.

            INDEMNITY

            You agree to defend, indemnify, and hold harmless Luxury Life Inc.’s officers, directors, employees, agents, affiliates, and subsidiaries from and against any claims, actions, or demands arising from your use of the Website, the Service, or your breach of the terms of this Agreement (including any breach of your representations and warranties contained herein), any postings or Content You post on the Website or as a result of the Service, and the violation of any law or regulation by You. You and your estate in the case of your death, further agree that this indemnification provision covers all third-party claims, actions, or demands, including those filed by your spouse, children, family, or others who rely on You for support. You also agree that this indemnification provision covers all damages, costs, or expenses related to claims described in this Section, including without limitation all reasonable legal and accounting fees. Luxury Life Inc. reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by You, in which event You will fully cooperate with Luxury Life Inc. in connection therewith.

            Summary: You agree to defend us and hold us harmless for your use of the Website and any breach by You of the Agreement terms or any violations of any law or regulation by You. This indemnification covers all claims, actions, or demands made by anyone.

            GENERAL PROVISIONS

            (i) Violation of this Agreement may cause Luxury Life Inc. irreparable harm, and You, therefore, agree that Luxury Life Inc. will be entitled to seek extraordinary relief in court, including but not limited to temporary restraining orders, preliminary injunctions, and permanent injunctions without the necessity of posting a bond or other security, in addition to and without prejudice to any other rights or remedies that Luxury Life Inc. may have for a breach of this Agreement.

            (ii) This Agreement (which You accept by using the Website and further affirm by becoming a Member of the Service), along with the Privacy Policy located on the Website and any applicable payment, renewal, and additional Service terms, comprise the entire agreement between You and Luxury Life Inc. regarding the use of the Website and/or the Service, superseding any prior agreements between You and Luxury Life Inc. related to your use of the Website or Service (including, but not limited to, any prior versions of this Agreement).

            (iii) Unless otherwise explicitly stated, this Agreement will survive termination of your registration to the Service.

            (iv) If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect.

            (v) The failure of Luxury Life Inc. to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.

            (vi) You agree that your online account is non-transferable and all of your rights to your profile or contents within your account terminate upon your death.

            (vii) No agency, partnership, joint venture, or employment is created as a result of this Agreement, and You may not make any representations or bind Luxury Life Inc. in any manner.

            Summary: These are general terms to which You agree, just like You are agreeing to all the other terms in this agreement.

            SPECIAL STATE TERMS

            The following provisions are added to this Agreement for subscribers residing in Arizona, California, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, and Wisconsin:

            You, the buyer, may cancel this Agreement, without penalty or obligation, at any time prior to midnight of the third business day following the original date of this contract, excluding Sundays and holidays. To cancel this Agreement, mail or deliver a signed and dated notice which states that You, the buyer, are canceling this Agreement, or words of similar effect. This notice shall be sent to: Luxury Life Inc., Attn: Terms of Use Manager. Please include your username and email address in any correspondence or your refund may be delayed. If You cancel, Luxury Life Inc. will return, within ten days of the date on which You give notice of cancellation, any payments You have made. If You send or deliver the notice to cancel your subscription agreement within such a three-day period, we will refund the full amount of your subscription. In the event that You die before the end of your subscription period, your estate shall be entitled to a refund of that portion of any payment You had made for your subscription which is allocable to the period after your death. In the event that You become disabled (such that You are unable to use the services of Luxury Life Inc. before the end of your subscription period, You shall be entitled to a refund of that portion of any payment You had made for your subscription which is allocable to the period after your disability by providing the company notice at the same address as listed above.

            Summary: If You are a resident of one of the above states, the above terms apply to You and supersede any contradictory terms that might be found otherwise in the Agreement.

            NOTICE

            The Company may provide You with notices, including those regarding changes to this Agreement, using any reasonable means now known or hereafter developed, including by email, regular mail, SMS, MMS, text message, or postings on the Website. Such notices may not be received if You violate this Agreement by accessing the Service in an unauthorized manner. You agree that You are deemed to have received any and all notices that would have been delivered had You accessed the Service in an authorized manner.

            Summary: If we make changes or want to notify You, we’ll let You know.

            REVISION DATE AND AMENDMENT

            This Agreement is subject to change by the Company at any time. If You are a non-subscribing Member at the time of any change, the revised terms will be effective when posted on the Website, and your use of the Service after such posting will constitute acceptance by You of the revised Agreement. If You are a subscribing Member at the time of any change, to the extent permitted by applicable law, the then-existing Agreement will continue to govern your Membership until such time that your Membership is renewed (whether by You or automatically by the Service) as contemplated herein. If You continue your Membership, your renewal will constitute acceptance by You of the revised Agreement. Alternatively, if You terminate your subscription at such time, your use of the Service after your termination will constitute acceptance by You of the then-existing Agreement.

            Summary: When we revise this Agreement, depending on your status (non-subscribing or subscribing), You may be subject to different terms (old or new) for a period of time.

          </p>
        </div>
      </Container>
      <Footer />
    </div>
  )
}

export default TermsAndConation
