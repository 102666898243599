import React from "react";
import { clamp, get } from "lodash-es";
import ErrorPageComponent from "src/components/ErrorPageComponent";
import { format } from "timeago.js";
import moment from "moment";

export const isObject = (target) =>
  Object.prototype.toString.call(target) === "[object Object]";

export const groupBy = function (xs, key) {
  return xs.reduce((rv, x) => {

    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const allPossiblePathOfObject = function (tree) {
  const leaves = [];
  const walk = function (obj, path) {

    path = path || "";
    for (const n in obj) {
      if (obj.hasOwnProperty(n)) {
        if (n[0] === "$") {
          leaves.push(`${path ? `${path}` : ""}`);
        } else if (
          (typeof obj[n] === "object" || obj[n] instanceof Array) &&
          !(obj[n] instanceof Date)
        ) {
          walk(obj[n], `${path ? `${path}.` : ""}${n}`);
        } else {
          leaves.push(`${path ? `${path}.` : ""}${n}`);
        }
      }
    }
  };
  walk(tree, "");
  return leaves;
};

export const buildSearchWithNoCase = ({
  where,
  fullTextSearch,
  caseSensitiveSearchProperties = [],
}) => {
  const paths = allPossiblePathOfObject(where);
  const dotNotationWhere = {};
  paths.forEach((path) => {
    const value = get(where, path);
    if (value !== undefined && value !== null) {
      if (!fullTextSearch || caseSensitiveSearchProperties.indexOf(path) > -1) {
        dotNotationWhere[path] = value;
      } else if (
        value === "false" ||
        value === "true" ||
        value === false ||
        value === true ||
        typeof value === "number" ||
        typeof value === "object" ||
        Array.isArray(value)
      ) {
        dotNotationWhere[path] = value;
      }

      else if (
        path[path.length - 1] === "d" &&
        (path[path.length - 2] === "i" || path[path.length - 2] === "I") &&
        value.toString().length === 24
      ) {
        dotNotationWhere[path] = value;
      } else {
        dotNotationWhere[path] = {
          $regex: `^${value}`,
          $options: "i",
        };
      }
    }
  });

  return dotNotationWhere;
};

export const setEmptyStringToNull = function (tree) {
  const walk = function (target) {
    for (const key in target) {
      if (Object.prototype.hasOwnProperty.call(target, key)) {
        if (typeof target[key] === "object" || target[key] instanceof Array) {
          walk(target[key]);
        } else if (typeof target[key] === "string" && target[key] === "") {
          target[key] = null;
        }
      }
    }
  };
  walk(tree);
  return tree;
};

export function permissionsToTreeStructure(permissions, path = "") {
  if (!permissions) {
    return [];
  }

  const keys = Object.keys(permissions);

  const scopesAtLevel = [];

  keys.forEach((key) => {
    const value = permissions[key];
    let children = null;
    const currentPath = path ? `${path}.${key}` : key;

    if (value.toString() === "[object Object]") {
      children = permissionsToTreeStructure(value, currentPath);
    }

    scopesAtLevel.push({
      value: currentPath,
      label: key,
      children,
    });
  });

  return scopesAtLevel;
}

export function hasPermission({ scope, scopeList }) {
  if (!scopeList?.length) {
    return false;
  }

  if (!scope) {
    return true;
  }

  const scopeSplit = scope.split(".");

  const has = scopeList.find((permission) => {
    const permissionSplit = permission?.split(".");
    const result = scopeSplit.every(
      (item, index) => item === permissionSplit[index]
    );
    return result;
  });

  return Boolean(has);
}

export const UnAuthorizedErrorPage = () => (
  <ErrorPageComponent code="401" title="Unauthorized" />
);


export const formatSize = (size) => {
  if (size < 1024) {
    return size + " KB";
  } else if (size < 1024 * 1024) {
    return (size / 1024).toFixed(2) + " KB";
  } else if (size < 1024 * 1024 * 1024) {
    return (size / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
};

export const getStorageUsageGroupBy = (data) => {

  const groupedData = {
    Total: { count: 0, totalSize: 0 },
    Text: { count: 0, totalSize: 0 },
    Images: { count: 0, totalSize: 0 },
    Video: { count: 0, totalSize: 0 },
    Audio: { count: 0, totalSize: 0 },
    Documents: { count: 0, totalSize: 0 },
    Others: { count: 0, totalSize: 0 },
  };


  data.forEach((item) => {
    const fileType = item._id;
    const count = item.count;
    const totalSize = item.totalSize;


    if (!fileType) {
      groupedData.Text.count += count;
      groupedData.Text.totalSize += totalSize;
    } else if (fileType.startsWith("image/")) {
      groupedData.Images.count += count;
      groupedData.Images.totalSize += totalSize;
    } else if (fileType.startsWith("video/")) {
      groupedData.Video.count += count;
      groupedData.Video.totalSize += totalSize;
    } else if (fileType.startsWith("audio/")) {
      groupedData.Audio.count += count;
      groupedData.Audio.totalSize += totalSize;
    } else if (
      fileType.startsWith("application/pdf") ||
      fileType.startsWith("application/msword") ||
      fileType.startsWith("application/vnd.openxmlformats-officedocument")
    ) {
      groupedData.Documents.count += count;
      groupedData.Documents.totalSize += totalSize;
    } else {
      groupedData.Others.count += count;
      groupedData.Others.totalSize += totalSize;
    }

    groupedData.Total.count += count;
    groupedData.Total.totalSize += totalSize;
  });


  for (const category in groupedData) {
    groupedData[category].totalSize = formatSize(
      groupedData[category].totalSize
    );
  }

  return groupedData;
};
export const generateStrongPassword = (length = 12) => {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+{}:"<>?[];,./`~';
  return Array.from(
    { length },
    () => chars[Math.floor(Math.random() * chars.length)]
  ).join("");
};

export const copyToClipboard = (value) => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      console.log("Text copied to clipboard");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
};


const unitMap = {
  B: 1,
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
};

const parseSizeToBytes = (sizeString) => {
  const [size, unit] = sizeString.split(" ");
  return parseFloat(size) * unitMap[unit];
};

export const getTotalSize = (data) => {
  let totalBytes = 0;

  for (const key in data) {
    const size = data[key];
    totalBytes += parseSizeToBytes(size);
  }
  return totalBytes;
};

export const calculateOccupiedPercentage = (occupiedSize, totalAllocation) => {

  const occupiedBytes = parseSizeToBytes(occupiedSize);
  const totalAllocationBytes = parseSizeToBytes(totalAllocation);

  const percentage = (occupiedBytes / totalAllocationBytes) * 100;

  return percentage.toFixed(2);
};
export const time_ago = (date_time) => {
  return format(moment(date_time).format("x"));
};
export const getCurrentTime = (date) => {
  const currentDate = date ? new Date(date) : new Date();
  const today = new Date();
  const isToday =
    currentDate.getDate() === today.getDate() &&
    currentDate.getMonth() === today.getMonth() &&
    currentDate.getFullYear() === today.getFullYear();

  let timeString;

  if (isToday) {
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    timeString = hours + ":" + minutes + " " + ampm;
  } else {
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    minutes = minutes < 10 ? "0" + minutes : minutes;
    timeString = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  return timeString;
};



export const formatDate = (date) => {
  const givenDate = date ? new Date(date) : new Date();
  const weekDay = givenDate.toLocaleString("en-US", { weekday: "short" });
  const month = givenDate.toLocaleString("en-US", { month: "short" });
  const day = givenDate.getDate().toString().padStart(2, "0");
  const year = givenDate.getFullYear();
  return `${weekDay}, ${month} ${day}, ${year}`;
};

export const getDaysDifference = (dateString) => {
  if (!dateString) {
    return false; // Handle blank input
  }

  try {
    // Parse the input date
    const specificDate = new Date(dateString);

    // Check if the parsed date is valid
    if (isNaN(specificDate)) {
      return false; // Invalid date
    }

    // Get today's date (or use a custom end date by replacing `new Date()`)
    const today = new Date();

    // Calculate the difference in milliseconds
    const differenceInMs = today - specificDate;

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    return differenceInDays; // Return the difference in days
  } catch (error) {
    return false; // Handle unexpected errors
  }
};


export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const getLast7Days = () => {
  const today = new Date();
  const last7Days = new Date();
  last7Days.setDate(today.getDate() - 6);
  return { start: last7Days, end: today };
};

export const getThisMonth = () => {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth(), 1);
  const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return { start, end };
};

export const getLastMonth = () => {
  const today = new Date();
  const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const end = new Date(today.getFullYear(), today.getMonth(), 0);
  return { start, end };
};

export const checkIfLinkType = (fileIds, message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  if (urlRegex.test(message)) {
    return fileIds?.length > 0 ? "media" : "link";
  } else {
    return fileIds?.length > 0 ? "media" : "text";
  }
};

export const debounceFun = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export const generateMonthData = (year, month, dateWiseProductions, dateWiseLeaves, dateWiseHubStaff) => {
  const monthData = [];
  const daysInMonth = new Date(year, month + 1, 0).getDate(); // get total days in the month
  let week = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(Date.UTC(year, month, day)); // Create date in UTC
    const formattedDate = `${String(currentDate.getUTCDate()).padStart(2, '0')}-${String(currentDate.getUTCMonth() + 1).padStart(2, '0')}-${currentDate.getUTCFullYear()}`;
    const dayOfWeek = currentDate.getUTCDay(); // get the day of the week in UTC (0 for Sunday, 6 for Saturday)
    const hubStaff = dateWiseHubStaff?.find(item => item.Date === formattedDate);

    week.push({
      date: currentDate,
      dayOfWeek,
      activity: hubStaff?.Activity || "NA",
      time: hubStaff?.Time || "NA",
      label: getDayLabel(currentDate, dateWiseProductions, month, year), // Pass month to determine 2nd and 4th Saturday
    });

    if (dayOfWeek === 6 || day === daysInMonth) { // if Saturday or last day of the month
      monthData.push(week);
      week = [];
    }
  }

  return monthData;
};

function getDayLabel(date, dateWiseProductions, month, year) {
  const dateString = date.toISOString().split('T')[0]; // Convert date to 'YYYY-MM-DD' format

  // Check if the date exists in dateWiseProductions
  if (dateWiseProductions.includes(dateString)) {
    return 'P'; // Mark as Present if exists in dateWiseProductions
  }

  const dayOfWeek = date.getUTCDay(); // Get the day of the week in UTC
  const dayOfMonth = date.getUTCDate(); // Get the day of the month in UTC

  // Check for Sunday (0) or 2nd and 4th Saturday
  if (dayOfWeek === 0 || (dayOfWeek === 6)) {
    return 'W'; // Mark as Holiday
  }
  return false; // Return false if none of the conditions are met
}

function isSecondOrFourthSaturday(day, month, year) {
  let saturdayCount = 0;

  for (let i = 1; i <= day; i++) {
    const currentDate = new Date(Date.UTC(year, month, i)); // Create date in UTC
    if (currentDate.getUTCDay() === 6) { // Saturday
      saturdayCount++;
    }
  }

  return saturdayCount === 2 || saturdayCount === 4; // True if it's the 2nd or 4th Saturday
}

// function getDayLabel(date,dateWiseProductions) {
//   // Example logic to label days (you can modify this as needed)
//   const holidays = []; // Add your holidays here
//   const sickLeave = []; // Example sick leaves
//   const casualLeave = [];

//   const dateString = date.toISOString().split('T')[0];

//   if (holidays.includes(dateString)) return 'H';
//   if (sickLeave.includes(dateString)) return 'SL';
//   if (casualLeave.includes(dateString)) return 'CL';
//   return 'P'; // Default to Present
// }


export const utilMonthOptions = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  // Map months to the desired format
  const monthsData = months.map((month, index) => ({
    label: month,
    value: String(index + 1) // .padStart(2, "0") // Convert to 2-digit format
  }));
  return monthsData
}

export const getMonthLabel = (value) => {  
  const result = utilMonthOptions().find(item => item.value === String(value));
  return result ? result.label : "NA";
};

export const costFormat = (num, symbol = '₹') => {
  const number = Number(num);
  const roundedNum = Math.round(number * 100) / 100; // Round to 2 decimal places
  const formattedCost = roundedNum.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 2, // Ensure two decimal places
    maximumFractionDigits: 2
  });
  return formattedCost.replace(/₹/, symbol); // You can also adjust the currency symbol if needed
};
export const truncateNotes = (notes) => {
  return notes && notes.length > 15 ? `${notes.substring(0, 15)}...` : notes || '';
};

export const fullMonthName = (monthNumber) => {
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Ensure monthNumber is within the range of 1 to 12
  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number";
  }

  return monthNames[monthNumber - 1];  // Adjust for 0-indexing
};



export const calculateDeviation = (a, b) => {
  b = (b === 0) ? 0 : ((a * 100) / b) - 100;
  return b.toFixed(2);
};

export const calculatePercentage = (a, b) => {
  b = (b === 0) ? 0 : (a * 100) / b;
  return b.toFixed(2);
};

export default {
  isObject,
  calculateDeviation,
  calculatePercentage,
  groupBy,
  getDaysDifference,
  truncateNotes,
  costFormat,
  fullMonthName,
  allPossiblePathOfObject,
  buildSearchWithNoCase,
  setEmptyStringToNull,
  permissionsToTreeStructure,
  hasPermission,
  UnAuthorizedErrorPage,
  getStorageUsageGroupBy,
  generateStrongPassword,
  copyToClipboard,
  getTotalSize,
  formatSize,
  calculateOccupiedPercentage,
  time_ago,
  getCurrentTime,
  checkIfLinkType,
  debounceFun,
  generateMonthData,
};
