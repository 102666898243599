// const API = "https://luxurylife.ai/"
// export default { 
//   baseUrl: `${API}/api/v1/`,
//   imageUrl: "https://luxurylife.ai/uploads/",
// };

const API = "https://luxurylife.ai/"
export default { 
  baseUrl: `${API}/api/v1/`,
  imageUrl: "https://luxurylife.ai/uploads/",
};
