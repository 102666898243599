export const SIGNUP_ACTIONS = {
    SIGNUP_SEARCH: 'SIGNUP_SEARCH',
    SIGNUP_SEARCH_SUCCESS: 'SIGNUP_SEARCH_SUCCESS',
    SIGNUP_SEARCH_ERROR: 'SIGNUP_SEARCH_ERROR',

    SIGNUP_INVOICE_SEARCH: 'SIGNUP_INVOICE_SEARCH',
    SIGNUP_INVOICE_SEARCH_SUCCESS: 'SIGNUP_INVOICE_SEARCH_SUCCESS',
    SIGNUP_INVOICE_SEARCH_ERROR: 'SIGNUP_INVOICE_SEARCH_ERROR',

    SIGNUP_UPSERT: 'SIGNUP_UPSERT',
    SIGNUP_UPSERT_SUCCESS: 'SIGNUP_UPSERT_SUCCESS',
    SIGNUP_UPSERT_ERROR: 'SIGNUP_UPSERT_ERROR',

    SIGNUP_EDIT: 'SIGNUP_EDIT',

    SIGNUP_RESET: 'SIGNUP_RESET',
    SET_PAYLOAD: 'SET_PAYLOAD',
};

export const signupResetAction = () => ({
    type: SIGNUP_ACTIONS.SIGNUP_RESET,
});

export const signupSearchAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_SEARCH,
    ...payload,
});

export const signupEditAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_EDIT,
    ...payload,
});

export const signupUpsertAction = (payload) => ({
    type: SIGNUP_ACTIONS.SIGNUP_UPSERT,
    ...payload,
});
export const setPayload = (payload) => ({ type: SIGNUP_ACTIONS.SET_PAYLOAD, ...payload });

export default {
    SIGNUP_ACTIONS,
    signupSearchAction,
    signupUpsertAction,
    signupEditAction,
    setPayload,
};
