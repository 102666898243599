import VideoBanner from "src/Component/Home/VideoBanner"
import Footer from "./layout/Footer"
import Header from "./layout/Header"
import WhySettle from "src/Component/Home/WhySettle"
import RedefiningModern from "src/Component/Home/RedefiningModern"
import LuxuryText from "src/Component/Home/LuxuryText"
import YourExclusive from "src/Component/Home/YourExclusive"
import HowLuxury from "src/Component/Home/HowLuxury"
import YourPrivacySec from "src/Component/Home/YourPrivacySec"
import SlideSlider from "src/Component/Home/SlideSlider"
import ReadyToExperience from "src/Component/Home/ReadyToExperience"

const HomeContainer = () => {
    return (
        <div>
            <Header />

            <VideoBanner />
            <WhySettle />
            <RedefiningModern />
            <LuxuryText />
            <YourExclusive />
            <HowLuxury />
            <YourPrivacySec />
            <SlideSlider />
            <ReadyToExperience />

            <Footer />
        </div>
    )
}
export default HomeContainer