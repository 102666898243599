import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const signupSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/signup/search`;
    return postRequest({
        url,
        data,
    });
};
export const signupCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/signup/save`;
    return postRequest({
        url,
        data,
    });
};

export const signupUpdateAPI = function ({
    signupId,
    data,
}) {
    const url = `${config.baseUrl}admin/signup/update/${signupId}`;
    return putRequest({
        url,
        data,
    });
};
