import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const SignupPropTypes = PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
});

export const SignupDefaultProps = {
    userName: '',
    email: '',
    Step: 1,
    dateOfBirth: '',
    gender: '',
    tall: {
        unit: 'cm',
        value: 0
    },
    bodyType: '',
    ethnicity: '',
    hobbies: [],
    smoke: '',
    drink: '',
    luxuryInterests: [],
    aboutUsDescription: '',
    myHeading: '',
    highestEducation: '',
    workField: '',
    currentRelationshipStatus: '',
    children: '',
    incomeRange: '',
    netWorthRange: '',
    bio: '',
    location: {
        type: "Point",
        coordinates: [
            0, //longitude
            0  //latitude
        ]
    },
    city: '',
    state: '',
    country: '',
    realTimePicture: '',
    profilePicture: '',
    publicPhotos: [],
    privatePhotos: [],
    otp: '',
    isOtpVerified: false,
    isProfileVerified: false,
    isIdVerified: false,
    securityQuestionsIds: [],
    steps: Array(25).fill(false),
    _id: undefined
};

export const SignupYupSchema = Yup.object().shape({
    // firstName: Yup.string().required('Required'),
    // phoneNumber: Yup.number().required("Required").positive().integer().min(9, 'Invalid phone number'),
    // email: Yup.string().required('Required').email('Invalid email format'),
    // panCardNo: Yup.string()
    // .required('Required')
    // .matches(
    //   /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    //   'Invalid PAN card format. It should be 10 characters: first 5 letters, then 4 numbers, and last 1 letter.'
    // ),
    // accountNumber: Yup.number().required("Required").positive("Invalid account number").integer("Invalid account number"),
    // ifscCode: Yup.string().required('Required'),
    // bankName: Yup.string().required('Required'),
    // state: Yup.string().required('Required'),
    // city: Yup.string().required('Required'),
    // pincode: Yup.string().required('Required'),
    // services: Yup.string().required('Required'),
    // accountHolderName: Yup.string().required('Required'),
    // accountNumber: Yup.string().required('Required'),
    // documentType: Yup.string().required('Required'),
});
export const SignupPaymentYupSchema = Yup.object().shape({
    date: Yup.string().required('Required'),
    amount: Yup.number().required("Required").positive().integer().min(1, 'Invalid Amount'),
});

export default {
    SignupDefaultProps,
    SignupPropTypes,
    SignupYupSchema,
};
