import { Container } from '@mui/material'
import React from 'react'
import Header from './layout/Header'
import Footer from './layout/Footer'

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <Container maxWidth="xl">
        <div className="" style={{ marginTop: '120px' }}>
          <h2>Privacy Policy</h2>
          <p>
            Introduction
            Luxury Life, Inc. is committed to protecting your privacy. This Privacy Policy outlines the types of personal information we collect from users of our website, mobile application, and other platforms or technologies (collectively referred to as the "Site(s)") and how we use, share, and protect that information.

            By using the Site(s), registering for the Services, or by otherwise interacting with our Services, you consent to the collection and use of your personal information as described in this Privacy Policy. You agree to be bound by this Privacy Policy and the terms set forth in the related Service Agreement.

            Please read this Privacy Policy carefully to understand our views and practices regarding your personal data and how we will treat it.

            If you continue to use our Services, or if you click "I AGREE" or similar buttons, you acknowledge that you have read and understood the terms of this Privacy Policy and agree to its terms. This Privacy Policy may be updated from time to time, and any changes will be reflected on this page.

            For your convenience, we provide a link to our Service Agreement, which is an integral part of your use of our Site(s): [luxurylife.ai/serviceagreement].

            This Privacy Policy is a binding contract between you and the Company, and we encourage you to keep a copy for your records.

            In some instances, we may share your personal information with trusted third-party partners and service providers in connection with the Services. However, we do not control the privacy practices of third-party websites or services, and you should review their privacy policies separately.

            If you have any questions or concerns about this Privacy Policy, please contact us directly at support@luxurylife.ai.

            The Personal Data That We Collect
            When you use the Services, we may ask for certain personal information, such as your name, email address, phone number, occupation, hobbies, and interests. Our website also automatically collects and logs information from your browser, including your IP address, browser type, and cookie data. As mentioned, we prioritize providing you with a safe and secure user experience. Therefore, before granting access to certain Services, we may request additional details to verify your identity, address, or other relevant information, and to manage risk and ensure compliance during our relationship.

            We may also collect information from third-party sources, such as identity verification and fraud prevention services, to enhance the security and quality of your experience. If you do not agree to the collection of such data, you may face limitations or restrictions in the Services available to you.

            Purposes Of Processing And Legal Bases
            We process your personal data for the following purposes and based on the corresponding legal bases under applicable data protection laws:

            (i) Account Creation and User Authentication
            We collect and process personal data to create and manage your account, verify your identity, and authenticate your access to the Services.
            Legal Basis: Performance of a contract (Article 6(1)(b) GDPR).

            (ii) Providing and Enhancing the Services
            We process your data to deliver the features and services on the platform, such as matching, communication, and profile management.
            Legal Basis: Performance of a contract (Article 6(1)(b) GDPR) and legitimate interests (Article 6(1)(f) GDPR) to improve our offerings.

            (iii) Communication with Users
            We use your data to send service-related updates, promotions, or responses to your inquiries.
            Legal Basis: Consent (Article 6(1)(a) GDPR) or legitimate interest (Article 6(1)(f) GDPR) for responding to inquiries.

            (iv) Personalization and Recommendations
            We process personal data to personalize your experience on the platform, including tailored match suggestions based on preferences.
            Legal Basis: Consent (Article 6(1)(a) GDPR) or performance of a contract (Article 6(1)(b) GDPR).

            (v) Fraud Prevention and Security
            We process data to monitor for fraudulent activities, secure user accounts and prevent misuse of the Services.
            Legal Basis: Legitimate interest (Article 6(1)(f) GDPR) to protect our platform and users.

            (vi) Legal Compliance and Dispute Resolution
            We may process personal data to comply with legal obligations, resolve disputes, and enforce agreements.
            Legal Basis: Legal obligation (Article 6(1)(c) GDPR) and performance of a contract (Article 6(1)(b) GDPR).

            (vii) Marketing and Promotional Communications
            With your consent, we may use your information to send targeted marketing and promotional content regarding our Services.
            Legal Basis: Consent (Article 6(1)(a) GDPR).

            Use of Personal Data
            Luxury Life uses this information to:

            Provide, maintain, and improve the Services;
            Personalize the content and matches you see on the platform;
            Offer Customer Service and support your account-related needs;
            Help protect the safety and security of Luxury Life and its members, including detecting and blocking suspected fraudsters, investigating and addressing misuse, and enforcing our Terms of Use and Privacy Policy;
            Keep you informed of updates to our services, security alerts, and other important administrative messages;
            Communicate with you regarding new features, offers, promotions, and events, and provide other updates and information we believe will be of interest to you (for details on how to opt out of such communications, please refer to “Choices” below); and
            Monitor and analyze trends, usage, and activities related to the Services to enhance the user experience.

            Automated Decision-Making
            Luxury Life may use automated systems and processes to make decisions about your use of the Services. These automated decisions may include but are not limited to, personalized match suggestions, content recommendations, and tailored offers based on your profile, preferences, and interactions on the platform.

            Such automated processes help us provide a more customized and relevant experience for you and improve the overall quality of our services. While we rely on automated systems for certain tasks, you have the right to request human intervention or challenge any decisions made through automated means.

            If you would like more information about our automated decision-making processes or would like to request a review of a decision made through these systems, please contact us at [support@luxurylife.ai].

            Providing Your Personal Data To Others
            At Luxury Life, we value your privacy and take great care in handling your personal information. However, there are specific circumstances in which we may share your personal data with third parties. These include:

            (i) Service Providers
            We may share your information with trusted third-party service providers who assist us in operating our Services, processing payments, providing customer support, and improving our platform. These providers are contractually obligated to protect your personal data and use it solely to perform the services for which they were engaged.

            (ii) Business Partners
            We may share your information with select business partners, including affiliate sites and third-party platforms, to enhance your experience on our platform. Such sharing will only occur when necessary to provide the Services and based on your consent or in accordance with this Privacy Policy.

            (iii) Legal Compliance
            We may disclose your personal data when required by law, such as in response to a subpoena, court order, or other legal process. Additionally, we may disclose your data to protect the rights, property, or safety of Luxury Life, our users, or others, including preventing fraud or illegal activities.

            (iv) Corporate Transactions
            If we undergo a merger, acquisition, or asset sale, your personal data may be transferred as part of that transaction. In such cases, we will notify you via email or a prominent notice on our Site of any changes in ownership or uses of your personal data.

            (v) Other Users
            As part of using our platform, certain information (such as your profile details, preferences, and interactions) may be visible to other users. We allow you to control the visibility and scope of the information shared with others, as described in your privacy settings on the platform.

            We do not sell or rent your personal data to third parties for marketing purposes without your explicit consent.

            International Transfers Of Your Personal Data

            (i) Data Transfers
            By using our Services, you acknowledge and agree that your personal data may be transferred to and stored in locations outside of your country of residence, including jurisdictions that may not offer the same level of data protection as your home country. Specifically, as Luxury Life operates globally, your personal data may be transferred to, processed, and stored in countries such as the United States or other jurisdictions where our servers or service providers are located.

            (ii) Safeguards for Data Transfers
            In order to ensure that your personal data remains protected when transferred internationally, we implement appropriate safeguards in accordance with applicable data protection laws. These safeguards may include but are not limited to, the use of standard contractual clauses approved by the European Commission or reliance on adequacy decisions issued by competent authorities. These measures ensure that your personal data continues to receive an adequate level of protection in line with the standards required by applicable laws.

            (iii) Consent to International Transfers
            By accessing or using our Services, you consent to the transfer of your personal data to countries outside of your home country, as described in this section, including jurisdictions where data protection laws may differ from those in your country of residence.

            (iv) Right to Withdraw Consent
            You may have the right to withdraw your consent to the international transfer of your personal data at any time, although such withdrawal may affect your ability to use certain features or aspects of our Services. If you wish to withdraw your consent or have any questions regarding the international transfer of your personal data, please contact us using the details provided in the "Contact Us" section of this Privacy Policy.

            Retaining And Deleting Personal Data

            (i) Retention of Personal Data
            We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, as outlined in this Privacy Policy or as required by applicable law. The criteria we use to determine the retention period for your personal data include:

            The duration of your relationship with us as a Member or user of the Services;
            The purposes for which we collected your personal data and whether these purposes can be fulfilled during the retention period;
            Any legal, regulatory, or contractual obligations that require us to retain your personal data, such as tax or financial reporting requirements;
            The necessity to resolve disputes, enforce our agreements, or protect our legal rights.

            (ii) Deletion of Personal Data
            Once the retention period for your personal data expires, or upon your request, we will securely delete or anonymize your personal data in a manner that ensures it can no longer be used to identify you. If deletion is not possible for legal or technical reasons, we will ensure that your personal data is securely stored and isolated from further processing.

            (iii) Your Rights Regarding Data Retention
            You may request the deletion of your personal data at any time by contacting us using the details provided in the "Contact Us" section of this Privacy Policy. However, please note that we may not be able to fulfill your request if retention is necessary for compliance with our legal obligations or for the establishment, exercise, or defense of legal claims.

            (iv) Anonymization of Data
            In certain cases, rather than deleting personal data, we may anonymize your data to ensure it can no longer be associated with you. This anonymized data may then be retained for analytical, statistical, or research purposes, provided it no longer identifies you personally.

            Your Rights

            (i) Right to Access
            You have the right to request confirmation as to whether or not we are processing your personal data. If we are processing your personal data, you have the right to access such data and receive a copy of the personal data we hold about you. This right allows you to verify the accuracy of your data and ensure that it is being processed in accordance with applicable law.

            (ii) Right to Rectification
            You have the right to request that we correct any inaccuracies in your personal data or complete any incomplete personal data we hold about you. You may update your personal data by accessing your account settings or by contacting us directly.

            (iii) Right to Erasure ("Right to be Forgotten")
            You have the right to request the deletion of your personal data, subject to certain conditions. We may not be able to fulfill this request if retention is necessary for compliance with legal obligations, to establish, exercise, or defend legal claims, or for other legitimate purposes. Upon receiving a valid request, we will delete your personal data in accordance with applicable law and our data retention policies.

            (iv) Right to Restriction of Processing
            You have the right to request that we restrict the processing of your personal data in certain circumstances, such as when the accuracy of your data is contested or you have objected to the processing, pending verification of the legitimate grounds for processing.

            (v) Right to Data Portability
            You have the right to request a copy of your personal data in a structured, commonly used, and machine-readable format. You may also request that we transmit your personal data to another data controller, where technically feasible and subject to applicable law.

            (vi) Right to Object
            You have the right to object to the processing of your personal data, including the processing of your personal data for direct marketing purposes. If you object to the processing of your data, we will cease processing unless we can demonstrate compelling legitimate grounds for continuing the processing, which overrides your rights and interests.

            (vii) Right to Withdraw Consent
            If you have provided consent for the processing of your personal data, you have the right to withdraw your consent at any time. Withdrawing consent will not affect the lawfulness of processing based on consent before its withdrawal.

            (viii) Right to Lodge a Complaint
            If you believe that the processing of your personal data violates applicable data protection laws, you have the right to lodge a complaint with the relevant data protection authority in your jurisdiction.

            (ix) Exercising Your Rights
            To exercise any of the above rights, please contact us using the contact details provided in the "Contact Us" section of this Privacy Policy. We will respond to your request in accordance with applicable data protection laws.

            Third Party Websites

            (i) External Links
            Our Service may contain links to websites, platforms, or services operated by third parties ("Third Party Websites"). These Third Party Websites are not under our control, and we are not responsible for the content, privacy policies, or practices of such Third Party Websites. When you click on a link to a Third Party Website, you do so at your own risk, and we encourage you to review the privacy policy and terms of use of any Third Party Website before providing them with any of your personal data.

            (ii) Third Party Services
            In addition to external links, we may provide features or services on our Site that allow you to interact with Third Party Websites, including social media platforms, payment processors, or other third-party services. These interactions are governed by the privacy policies and terms of use of the third-party service providers, and we encourage you to review those policies before engaging with such third-party services.

            (iii) Data Sharing with Third Parties
            As described in this Privacy Policy, we may share your personal data with third-party service providers who perform functions on our behalf, such as hosting, analytics, customer service, and marketing. These third parties are contractually obligated to safeguard your personal data and are prohibited from using it for purposes other than those for which it was provided.

            (iv) No Responsibility for Third Party Content
            We do not endorse or make any representations regarding the accuracy, completeness, or reliability of any content, advertisements, products, or services available through Third Party Websites. Your use of any Third Party Website is solely at your own discretion and risk, and you agree that we shall have no liability in connection with any such use.

            (v) Changes to Third Party Websites
            We may update or change the third-party services and platforms we engage with, and we encourage you to review this section regularly to stay informed of any updates or modifications.

            Personal Data Of Children

            (i) Children's Privacy
            Our Service is intended for adults and is not designed for or directed at individuals under the age of 18. We do not knowingly collect, use, or disclose personal data from individuals under the age of 18 ("Minors"). If we become aware that we have inadvertently collected personal data from a Minor, we will take reasonable steps to delete such information as soon as possible.

            (ii) No Consent from Minors
            By using our Service, you confirm that you are at least 18 years of age and are legally able to provide consent to the processing of your personal data. If you are a parent or guardian and believe that your child has provided us with personal data, please contact us immediately using the contact information provided below, and we will take steps to delete such data.

            (iii) No Intentional Collection
            We do not intentionally collect or seek to collect sensitive personal data. Should we find such data has been collected, we will take prompt action to rectify the situation.

            (iv) Contact Information
            If you are a parent or guardian and would like to review or delete your child's personal data, or if you have any concerns regarding the processing of your child's personal data, please contact us at:
            [support@luxurylife.ai]

            Updating Information
            You can update your personal information at any time. To do so, simply login to your account on our website using your username and password, and you will be able to modify the information you have provided.

            Online Transactions
            When you make a payment online, your personal details and payment information are encrypted using SSL encryption technology before being transmitted over the Internet. While we strive to ensure the highest level of security, SSL technology makes it highly difficult for your information to be intercepted or stolen during the transmission process. All credit card transactions are securely processed through our trusted payment provider, Stripe. Information sent to Stripe is protected within a secure SSL environment, safeguarding it from unauthorized third-party access. Once we receive your order, it is securely stored on an SSL server. For Stripe’s privacy policy, please click HERE. If you wish to make a payment, your registration details will be transferred to them to help safeguard your payments and protect against unauthorized transactions.

            Accounts Payable or Collections
            It is your responsibility to ensure that your account(s) with us remain in good standing. In the event of an outstanding balance or if you dispute a payment with your credit card or financial institution, please be aware that we may disclose information related to your activity on our site to the bank and/or collections agency we partner with. This is for the purposes of verifying your identity, confirming service usage, proving that the service was provided, and/or recovering any unpaid debts owed to us.

            About Cookies
            We use cookies and similar technologies to enhance your experience while using our website and services. A "cookie" is a small file placed on your device that helps us improve the functionality of our Site, personalize content, and analyze how the Site is used. Cookies may be used to collect and store personal data, such as your IP address, browser type, and browsing habits.

            By using our Site, you consent to the use of cookies in accordance with this Privacy Policy. You can control and manage cookies through your browser settings. However, please note that disabling certain cookies may impact the functionality and performance of our website and services.

            Cookies That We Use
            We use the following types of cookies on our Site to provide a better user experience, analyze usage, and improve our services:

            (i) Essential Cookies
            These cookies are necessary for the operation of our Site and services. They enable you to navigate the Site and access its features, such as logging in to your account or completing transactions. Without these cookies, certain functionalities of the Site may not be available.

            (ii) Performance Cookies
            These cookies collect information about how visitors use our Site, such as the pages they visit and the links they click. This data helps us analyze traffic patterns and improve the performance of our Site. These cookies do not collect personal data and are used solely to improve the functionality and user experience of the Site.

            (iii) Functional Cookies
            Functional cookies allow us to remember your preferences, such as language settings or display preferences, to provide a more personalized experience. They help us customize the content and services we offer based on your usage.

            (iv) Targeting or Advertising Cookies
            These cookies are used to track your browsing behavior across different websites. They help us deliver personalized advertisements and content that are relevant to you. These cookies may be placed by third-party advertisers, and they may use the collected data to measure the effectiveness of their advertisements.

            (v) Third-Party Cookies
            We may allow third-party services, such as social media platforms and analytics providers, to place cookies on your device. These cookies help us integrate third-party tools and features on our Site. For example, you may be able to share content from our Site on social media platforms, and these third-party providers may collect data about your interactions.

            You can manage cookie preferences through your browser settings. If you choose to disable cookies, certain features of our Site may not function properly, and your experience may be limited.

            Cookies Used By Our Service Providers
            In addition to the cookies we use, certain third-party service providers that help operate our Site may also use cookies. These cookies enable our service providers to offer essential functionality, such as analytics, advertisements, and social media integration, on our behalf. The cookies set by these third parties are governed by the respective privacy policies of those service providers and not by this Privacy Policy. Below are the types of cookies used by these third-party providers:

            (i) Analytics Cookies
            We may use third-party analytics providers, such as Google Analytics, to help us understand how users interact with our Site. These service providers set cookies to collect information about website traffic and user behavior, which helps us optimize the Site's performance. The information collected is anonymized, and Google Analytics’ use of cookies is governed by Google’s Privacy Policy.

            (ii) Advertising Cookies
            Our advertising partners, including but not limited to, Google Ads and other ad networks, may use cookies to serve personalized ads to you based on your online behavior and interests. These cookies help our advertising partners to track the effectiveness of advertisements and display relevant ads to you. Please refer to the respective privacy policies of these third-party advertising networks for more information about their practices.

            (iii) Social Media Cookies
            We use third-party social media platforms, such as Facebook, Twitter, and Instagram, to enhance user engagement and allow sharing of content from our Site. These platforms may set cookies to track your interactions with their services while you use our Site, enabling social sharing features. The use of cookies by social media platforms is governed by their respective privacy policies.

            (iv) Payment Processor Cookies
            In order to facilitate payments for any paid services, we may use third-party payment processors. These providers use cookies to process payments securely and confirm transactions. Payment processors may collect and store your payment details, including credit card information, in a secure manner in accordance with their privacy policies.

            Please note that we do not control the cookies set by third-party service providers, and they may collect data independently. You are encouraged to review the privacy policies of these third parties for further information on their data collection and usage practices.

            If you do not wish to accept cookies from these third-party service providers, you may manage your cookie preferences directly through your browser settings. However, disabling cookies may affect your ability to fully access and use certain features of our Site.

            Managing Cookies
            You have the ability to control and manage the cookies that are set on your device. Most web browsers automatically accept cookies, but you can modify your browser settings to block or delete cookies if you prefer. Please be aware that restricting cookies may impact your ability to fully utilize the features and functionality of our Site. Below are the options available to manage cookies:

            (i) Browser Settings
            You can configure your browser settings to accept or reject cookies based on your preferences. Most browsers allow you to block or delete cookies from specific websites or all websites. Please refer to your browser’s help section for detailed instructions on how to manage your cookie settings.

            (ii) Cookie Consent Banners
            Upon your first visit to our Site, we may display a cookie consent banner to inform you of our use of cookies. You can accept or reject the use of non-essential cookies through this banner. If you choose to reject cookies, certain features of the Site may be unavailable or may not function as intended.

            (iii) Third-Party Cookie Management
            In addition to managing cookies via your browser settings, you may also manage cookies set by third-party service providers. Many third-party advertising and analytics providers allow you to opt out of their tracking through their respective websites or by using industry-standard opt-out tools such as the Network Advertising Initiative (NAI) or the Digital Advertising Alliance (DAA).

            (iv) Clear Cookies
            You can delete cookies stored on your device at any time by clearing your browser's cache or deleting cookies via the browser settings. Please note that clearing cookies will remove any preferences or settings you have selected, and you may be asked to provide this information again on future visits to the Site.

            Please note that while you can manage cookies through your browser settings or opt-out of certain cookies, some essential cookies are necessary for the proper functioning of the Site and cannot be disabled. By continuing to use our Site, you consent to the use of cookies as described in this Privacy Policy unless you adjust your browser settings to block them.

            Cookie Preferences (Social Networking And Online Shop Only)
            Our site may use cookies to enhance your experience when interacting with third-party social networking platforms or online shopping services that we partner with. These cookies are placed by the third-party service providers to allow functionality such as sharing content on social media, personalized advertisements, or seamless purchasing experiences.

            You have the option to control your cookie preferences related to these services. You can adjust your cookie settings directly through your browser, or by visiting the privacy settings of the third-party platforms you interact with. Please be aware that disabling these cookies may impact your ability to use certain features of social networking tools or online shopping functionalities, such as the ability to share content or make purchases seamlessly through our site.

            By continuing to interact with our site or any integrated third-party platforms, you consent to the use of these cookies unless you choose to disable them.

            For more details on how these third-party services use cookies, please refer to their individual privacy policies.

            Your Preferences and Data Rights
            We provide you with choices about how we collect, use, and communicate your Personal Data.

            (i) Communication Preferences
            During registration, you can opt-in to receive promotional communications. If you later wish to opt-out, update your preferences in your account settings or contact us via email.
            To stop receiving promotional communications, follow the instructions in the messages. Even if you opt out, you may still receive essential communications related to your account or the Services.
            With your consent, we may send push notifications to your mobile device. These can be disabled anytime in your mobile device settings.

            (ii) Rights Under Data Protection Laws
            If you are in a region covered by data protection laws like the European Economic Area, you have the following rights regarding your Personal Data:

            Access: Request a copy of your Personal Data that we process.
            Rectification: Correct inaccuracies in your Personal Data.
            Withdrawal: Withdraw your consent for processing at any time.
            Deletion: Request that we delete your Personal Data.
            Restriction: Limit how we process your Personal Data.
            Transparency: Be informed about who your Personal Data has been shared with.

            (iii) Data Retention After Account Deactivation
            If you deactivate your account or request data deletion, we may retain certain information for legal, operational, or safety reasons, including:

            Analytics and record-keeping.
            Fraud prevention and security.
            Collection of owed payments.
            Enforcement of terms and conditions.
            Ensuring the integrity of our Services and users.

            If Personal Data has been shared with third parties prior to your deletion request, the handling of that data will be subject to those third parties' privacy policies, as outlined in this Privacy Policy.

            You can exercise your rights by contacting us using the details provided in this policy. We aim to respect your preferences while ensuring compliance with legal obligations and maintaining the safety of the Services.

            Amendments
            We reserve the right to amend, modify, or update this Privacy Policy at any time, in our sole discretion. Any changes or updates to this Privacy Policy will be posted on this page with an updated "Last Revised" date at the top of the policy. We may also notify you of significant changes to this Privacy Policy by email or other means, as appropriate.

            Your continued use of our Services following the posting of any amendments to this Privacy Policy constitutes your acceptance of those changes. If you do not agree with any changes to this Privacy Policy, you should discontinue your use of our Services and may close your account at any time.

            We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your personal data.

            Our Details
            The Services are provided by:

            Luxury Life Inc.

            For any inquiries or to exercise your rights as described in this Privacy Policy, you may contact us at:

            Email: [support@luxurylife.ai]

            If you have any questions regarding our Privacy Policy or how we handle your personal data, please feel free to contact us using the details provided above.

            Data Protection & Storage for Personal Information
            Your personal information is protected through physical, electronic, and procedural safeguards in compliance with applicable laws and regulations. We employ advanced computer security measures, including firewalls and data encryption, to protect your data. For example, we use SSL encryption to safeguard your payment information during e-commerce transactions. Additionally, we implement strict physical access controls to our offices and databases, and only authorized personnel who need access to your information for job-related purposes are permitted to do so.

            While we strive to maintain the highest levels of security, we cannot guarantee that personal information will not be accessed, disclosed, altered, or destroyed by a breach of our security measures. Therefore, we strongly encourage you to take appropriate precautions to protect your personal data, including not sharing your username and password associated with your account.

            In the event of a security breach, we may notify you electronically so that you can take the necessary precautions. By using our Services, you agree to receive such notifications electronically. We may post a notice on our website or mobile application or send an email to the address you have provided. Depending on your jurisdiction, you may have a legal right to receive a written notice of a security breach. If you wish to receive such notices in writing or withdraw consent for electronic notifications, please notify us of your preference here.

          </p>
        </div>
      </Container>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
