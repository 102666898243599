import React, { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

const LinkViaEmail = () => {
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    console.log("Captcha value:", value); // Optional: Log the captcha value
  };

//   const handleSubmit = () => {
//     if (!captchaValue) {
//       alert("Please complete the CAPTCHA to proceed.");
//       return;
//     }
//     alert("Form submitted successfully!");
//     // Proceed with form submission logic
//   };

  return (
    <div className="mw-400-px btnsWithConnects mx-auto"> 
     <div className="">
     <div className="font-m font-32 fw-medium text-center">Link Via Email</div>
      <div className="font-m font-14 colorf5 pt-6 text-center">
      Enter your email address below to proceed to the next step.
      </div>
     </div>


      <div className="icon-field my-32 w-100">
        <span className="icon">
          <MailOutlineIcon />
        </span>
        <input
          type="email"
          name="email"
          className="form-control input-h-44"
          placeholder="Enter Email"
        />
      </div>

      {/* Add reCAPTCHA */}
      <ReCAPTCHA className="mx-auto text-center d-flex justify-content-center mb-32"
        sitekey="YOUR_SITE_KEY" // Replace with your reCAPTCHA site key
        onChange={handleCaptchaChange}
      />
      <div className="bylinkTp font-m text-start">
      <div className="mb-3 form-check">
    <input type="checkbox" className="form-check-input" id="exampleCheck1" style={{width: '22px', height: '22px'}} />
    <label className="form-check-label ps-10" for="exampleCheck1">By linking your account, you agree to our <span className="d-block"><Link to="/">Terms</Link> and <Link to="/">Privacy Policy</Link>.</span></label>
  </div> 
    </div> 
    </div>
  );
};

export default LinkViaEmail;
