import React, { useEffect } from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'; // Import the autoScroll extension

const SlideSlider = () => {
  useEffect(() => {
    const splide = new Splide('.splide', {
      type: 'loop',
      drag: 'free',
      focus: 'center',
      autoScroll: {
        speed: 0.3, // Speed of auto-scroll
      },
      arrows: false, // Disable arrows
      pagination: false, // Disable pagination dots
      perPage: 9, // Default for large screens
      breakpoints: {
        1200: {
          perPage: 7, // For screens <= 1200px
        },
        992: {
          perPage: 6, // For screens <= 992px
        },
        768: {
          perPage: 6, // For screens <= 768px
        },
        576: {
          perPage: 5, // For screens <= 576px
        },
      },
    });

    splide.mount({ AutoScroll }); // Mount the autoScroll extension
  }, []);

  const images = [
    '/assets/images/slider-img/slider-img1.png',
    '/assets/images/slider-img/slider-img2.png',
    '/assets/images/slider-img/slider-img3.png',
    '/assets/images/slider-img/slider-img4.png',
    '/assets/images/slider-img/slider-img5.png',
    '/assets/images/slider-img/slider-img6.png',
    '/assets/images/slider-img/slider-img7.png',
    '/assets/images/slider-img/slider-img8.png',
    '/assets/images/slider-img/slider-img9.png',
    '/assets/images/slider-img/slider-img1.png',
    '/assets/images/slider-img/slider-img2.png',
    '/assets/images/slider-img/slider-img3.png',
    '/assets/images/slider-img/slider-img4.png',
    '/assets/images/slider-img/slider-img5.png',
    '/assets/images/slider-img/slider-img6.png',
    '/assets/images/slider-img/slider-img7.png',
    '/assets/images/slider-img/slider-img8.png',
    '/assets/images/slider-img/slider-img9.png',
  ];

  return (
    <div className="splide" role="group" aria-label="Splide Basic HTML Example">
      <div className="splide__track" id="splide-track">
        <ul className="splide__list">
          {images.map((src, index) => (
            <li className="splide__slide" key={index}>
              <img src={src} alt={`Slide ${index + 1}`} className="img-fluid w-100" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SlideSlider