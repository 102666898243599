import { combineReducers } from "redux";
import HomeReducer from './containers/home-container/reducer/HomeReducer';
import authReducer from "./containers/home-container/reducer/AuthReducer"
import SignupReducer from "./containers/signup-container/reducer/SignupReducer";


export default combineReducers({
    HomeReducer,
    auth: authReducer,
    SignupReducer
});
