import { all } from 'redux-saga/effects'; 
import HomeSaga from './containers/home-container/saga/HomeSaga';
import AuthSaga from './containers/home-container/saga/AuthSaga'; 
import SignupSaga from './containers/signup-container/saga/SignupSaga';
 

export default function* sagas() {
    yield all([
        ...HomeSaga,        
        ...AuthSaga, 
        ...SignupSaga
    ]);
}
