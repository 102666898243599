import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules'; 
import { Container } from '@mui/material';

const WhySettle = () => {

  // Dynamic slide data stored in an array of objects
  const slides = [
    {
      title: 'Challenges with Traditional Platforms',
      description: 'Endless swiping, little substance.',
      title2: 'With Luxury Life you can get',
      description2: 'AI-driven matching based on your preferences and lifestyle.',
      image: '/assets/images/challenges-img1.png',
      image2: '/assets/images/challenges-img1-icon.png',
    },
    {
      title: 'With Luxury Life you can get',
      description: 'AI-driven matching based on your preferences and lifestyle.',
      title2: 'Exclusive Privacy Features',
      description2: 'A platform designed with your privacy in mind.',
      image: '/assets/images/lack-of-privacy-img.png',
      image2: '/assets/images/lack-of-privacy-img-icon.png',
    },
    {
      title: 'Personalized Matches',
      description: 'AI-driven matching based on your preferences and lifestyle.',
      title2: 'Tailored for your Goals',
      description2: 'Connect with people who align with your aspirations.',
      image: '/assets/images/no-alignment-with-your-goals-img.png',
      image2: '',
    },
  ];

  return (
    <div style={{ background: '#000' }} className="SettleLess-Sec">
      <div className="slider-container text-center py-48 flex-column">
        <h2 className="animated-text font-48 text-white font-m fw-bold">
          Why <span className="fw-bold font-64 text-italic text-g font-p fw-bold">Settle</span> for Less?
        </h2>
        <Swiper
          direction="vertical"
          pagination={{ clickable: true }}
          loop={true}
          modules={[Autoplay, Pagination]} 
          className="custom-swiper"
          autoplay={{
            delay: 5000, // Time in milliseconds between slides (3 seconds)
            disableOnInteraction: false, // Autoplay continues after user interaction
          }} 
        >
          {/* Mapping through slides data dynamically */}
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <Container maxWidth="xl" className="slide-content SetMobDesn justify-content-center">
                <div className="text-section text-start px-4 animated-text mw-500-px w-100 topsetup desktopSet">
                  <h3 className="w-c40 font-24 fst-italic font-m fw-normal">{slide.title}</h3>
                  <p className="text-white font-36 font-m">{slide.description}</p>
                </div>
                <div className="image-section mw-500-px w-100 position-relative">
                  <img src={slide.image} alt={slide.title} className="slide-image" />
                  <div className="sliderImgPstion"><img src={slide.image2} alt={slide.title3} className="slide-image" /></div> 
                </div>
                {/* Only Mobile View */}
                <div className="text-section text-start px-4 animated-text mw-500-px w-100 topsetup MobileSet">
                  <h3 className="w-c40 font-24 fst-italic font-m fw-normal">{slide.title}</h3>
                  <p className="text-white font-36 font-m">{slide.description}</p>
                </div>
                {/* Only Mobile View end */}

                <div className="text-section text-start px-4 animated-text mw-500-px w-100 bottomsetup">
                  <h3 className="w-c40 font-24 fst-italic font-m fw-normal">{slide.title2}</h3>
                  <p className="text-white font-36 font-m">{slide.description2}</p>
                </div>
              </Container>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="container pz-2">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center pt-36 borderBtmGrnt animated-text">
                <p className="font-20 w-c40">Say goodbye to endless scrolling and hello to meaningful matches tailored to your aspirations and values.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySettle
