import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useScrollTrigger,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
  };

  // Detect scroll to trigger a different navbar style
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  // Detect if the background is light or dark
  const isDarkBackground = trigger; // Example condition for demo, adjust based on your background logic

  // Set text color based on background brightness
  const textColor = isDarkBackground ? '#fff' : '#000'; // Light text on dark, dark text on light

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: trigger ? 'rgba(0,0,0,0.3)' : 'transparent', // Change background color on scroll
          boxShadow: trigger ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
          WebkitBackdropFilter: trigger ? 'blur(6px)' : 'none',
          backdropFilter: trigger ? 'blur(6px)' : 'none',
          transition: 'box-shadow 0.3s, backdrop-filter 0.3s',
          top: 0,
          padding: '15px 0',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Left Menu Items */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-start',
            }}
          >
            {['About Us', 'Learn', 'Safety', 'Support'].map((item) => (
              <Button
                key={item}
                sx={{
                  color: textColor,
                  textTransform: 'none',
                  marginLeft: '1rem',
                }}
              >
                {item}
              </Button>
            ))}
          </Box>

          {/* Center Logo */}
          <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 0 }}>
            <img
              src="/assets/images/luxurylife.svg"
              className="img-fluid"
              alt="logo"
              style={{ height: '70px', maxWidth: '100%' }}
            />
          </Typography>

          {/* Right Menu Items */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            {[
              { text: 'Download', link: '/download' },
              { text: 'Login', link: '/login', className: 'login-btn rounded-pill px-4 fw-bold border-2-w' },
              { text: 'Signup For Free', link: '/signup', className: 'gradient-btn rounded-pill px-4 text-black fw-bold' },
            ].map((item) => (
              <Button
                key={item.text}
                sx={{
                  color: textColor,
                  textTransform: 'none',
                  marginLeft: '1rem',
                }}
                component={Link}
                to={item.link}
                className={item.className || 'custom-button-class'}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          {/* Hamburger Icon (Mobile View) */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'flex', md: 'none' }, color: textColor }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <Box sx={{ width: 250 }} onClick={handleDrawerToggle}>
          <List>
            {['About Us', 'Learn', 'Safety', 'Support', 'Download', 'Login', 'Signup For Free'].map((item) => (
              <ListItem key={item} disablePadding>
                <ListItemButton>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;
