import { AUTH_ACTIONS } from "src/containers/auth-container/actions/AuthActions";
import { LOGOUT } from "../saga/authActionTypes";

const initialState = {
  user: {
    ...JSON.parse(localStorage.getItem("user")),
    jwtToken: JSON.parse(localStorage.getItem("token")),
    userScopes: [],
  },
};

const resetState = {
  otpSentStatus: null,
  loginConfirmationStatus: null,
};
const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case "SET_USER_DETAILS":
      return {
        ...state,
        user: action.payload?.user,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload?.user,
      };
    case LOGOUT: {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        user: null,
      };
    }
    case 'UPDATE_USER_PROFILE': {
      const { field, value } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          otherInformation: {
            ...state.user.otherInformation,
            [field]: value,
          },
        },
      };
    }

    
    case AUTH_ACTIONS.FETCH_USER_DETAILS_SUCCESS: {  
        return {
            ...state,
            user:{
              ...state.user,
              ...action.payload?.userDetails
            },
        };
        break;
    }
    
    case AUTH_ACTIONS.FETCH_PERMISSION_SCOPES_SUCCESS: {
      return {
        ...state,
        user:{
          ...state.user,          
          userScopes: action.payload?.userScopes
        },
    };
        break;
    }

    default:
      return state;
  }
};

export default authReducer;
