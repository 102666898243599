import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'; 
import Footer from 'src/containers/home-container/layout/Footer';
import GenderInterestSelection from 'src/components/signup/GenderInterestSelection';
import TillUsDOB from 'src/components/signup/TillUsDOB';
import LinksYourAccount from 'src/components/signup/LinksYourAccount';
import LinkViaEmail from 'src/components/signup/LinkViaEmail';
import EmailVerification from 'src/components/signup/EmailVerification';
import CreateYourPassword from 'src/components/signup/CreateYourPassword';
import SecurityQuestionSetup from 'src/components/signup/SecurityQuestionSetup';
import UserName from 'src/components/signup/UserName';
import YourLocation from 'src/components/signup/YourLocation';
import HeightSelector from 'src/components/signup/HeightSelector';
import BodyType from 'src/components/signup/BodyType';
import YourEthnicity from 'src/components/signup/YourEthnicity';
import LevelOfEducation from 'src/components/signup/LevelOfEducation';
import YourField from 'src/components/signup/YourField';
import RelationshipStatus from 'src/components/signup/RelationshipStatus';
import HaveChildren from 'src/components/signup/HaveChildren';
import NetWorth from 'src/components/signup/NetWorth';
import AgePreferences from 'src/components/signup/AgePreferences';
import YourHobbies from 'src/components/signup/YourHobbies';
import DoYouSmoke from 'src/components/signup/DoYouSmoke';
import DoYouDrinkAlcohol from 'src/components/signup/DoYouDrinkAlcohol';
import LuxeInterests from 'src/components/signup/LuxeInterests';
import YouLookingForPartner from 'src/components/signup/YouLookingForPartner';
import PhotoUpload from 'src/components/signup/PhotoUpload';
import CreateCatchyHeading from 'src/components/signup/CreateCatchyHeading';
import FewWords from 'src/components/signup/FewWords';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';




const SignUpContainer = () => {
  const navigate = useNavigate(); 
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  const {
     currentRecord,
  } = useSelector((state) => state.SignupReducer, shallowEqual);
  const stepTexts = {
    1: "Your preferences help us tailor your matches according to your desires.",
    2: "You must be at least 18 years old to use this service.",
    3: " Linking your account does not grant us permission to post on your behalf without explicit consent.",
    4: "Linking your account does not grant us permission to post on your behalf without explicit consent.",
    5: "Please check your email and spam folder for the OTP.",
    6: "",
    7: "This information will be used for account recovery and will remain confidential.",
    8: "Note: Your name will only be visible to your matches or as per your privacy settings.",
    9: "Note: Your username will only be visible if you wish to display it on your profile for your matches to see.",
    10: "Your exact location is never shared with other users and will remain private.",
    11: "This detail will be visible to your matches and helps in creating better compatibility.",
    12: "This information helps create a more tailored experience for you and your matches.",
    13: "Sharing this detail is optional but helps personalize your matching experience.",
    14: "This detail helps us match you with people who share similar values and interests.",
    15: "This is optional, but it helps us create a more tailored experience for you.",
    16: "This information is necessary and will help us tailor your experience to find better matches.",
    17: "This detail is optional but may help create more compatible matches based on shared life goals.",
    18: "This helps match you with like-minded individuals while ensuring confidentiality.",
    19: "This helps match you with individuals who share similar financial goals.",
    20: "Your age preference ensures personalized matches for meaningful connections.",
    21: "These hobbies help us connect you with like-minded individuals who share your passions and lifestyle preferences.",
    22: "This information is public and can be viewed on your profile or kept hidden using filter options.",
    23: "This information is public and can be viewed on your profile or kept hidden using filter options.",
    24: "These tags help us match you with people who share your lifestyle preferences and passions.",
    25: "This detail is optional but will help us refine your matches based on your personal preferences and relationship goals.",
    26: "Make sure your photos follow our guidelines for a respectful and safe experience for everyone.",
    27: " Make sure your photos follow our guidelines for a respectful and safe experience for everyone.",
    28: "Tip: Be genuine, and let your personality shine through. Whether it’s your sense of humor, passion for adventure, or dedication to success, this is your moment to stand out.",
  };

  const handleContinue = () => {
    if (currentStep === 26) {
      // Redirect to another page (e.g., "/thank-you")
      navigate('/profilecontainer');
    } else if (currentStep < 26) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // const handleContinue = () => {
  //   if (currentStep < 28) setCurrentStep((prevStep) => prevStep + 1);
  // };

  const handleBack = () => {
    if (currentStep > 1) setCurrentStep((prevStep) => prevStep - 1);
  };
  const handleSkip = () => {
    // Customize the skip logic
    if (currentStep < 26) {
      setCurrentStep((prevStep) => prevStep + 1); // Skip to the next step
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (<div className="step step1"><GenderInterestSelection  /></div>);
      case 2:
        return (<div className="step step2"><TillUsDOB  /></div>);
      case 3:
        return (
          <div className="step step3">
            <LinksYourAccount
              firstName="Hisham"
              lastName="Sabur"
              email="hishamsabur@gmail.com"
              avatar=""
              AccountTitle="Great! Let’s Link Your Account"
              AccountSubTitle="Connect your account to get started quickly and securely."
              handleContinue={handleContinue}
            />
          </div>
        );
      case 4:
        return (<div className="step step4"><LinkViaEmail /></div>);
      case 5:
        return (<div className="step step5"><EmailVerification title="Verify Your Email" subtitle="Enter the verification code sent to your email to proceed." /></div>);
      case 6:
        return (<div className="step step6"><CreateYourPassword/></div>);
      case 7:
        return (<div className="step step7"><SecurityQuestionSetup /></div>);
      // case 8:
      //   return (<div className="step step8"><FullName /></div>);
      case 8:
        return (<div className="step step9"><UserName /></div>);
      case 9:
        return (
          <div className="step step10"> <YourLocation /></div>
        );
      case 10:
        return (
          <div className="step step11"><HeightSelector/></div>
        );
      case 11:
        return (
          <div className="step step12"> <BodyType/></div>
        );
      case 12:
        return (
          <div className="step step13"> <YourEthnicity/></div>
        );
      case 13:
        return (
          <div className="step step14"> <LevelOfEducation/></div>
        );
      case 14:
        return (
          <div className="step step15"><YourField/></div>
        );
      case 15:
        return (
          <div className="step step16"> <RelationshipStatus/></div>
        );
      case 16:
        return (
          <div className="step step17"><HaveChildren/></div>
        );
      // case 17:
      //   return (
      //     <div className="step step18"> <YourIncomeRange /></div>
      //   );
      case 17:
        return (
          <div className="step step19"> <NetWorth /></div>
        );
      case 18:
        return (
          <div className="step step20"> <AgePreferences/></div>
        );
      case 19:
        return (
          <div className="step step21"> <YourHobbies/></div>
        );
      case 20:
        return (
          <div className="step step22"> <DoYouSmoke/></div>
        );
      case 21:
        return (
          <div className="step step23"> <DoYouDrinkAlcohol/></div>
        );
      case 22:
        return (
          <div className="step step24"> <LuxeInterests/></div>
        );
      case 23:
        return (
          <div className="step step25"> <YouLookingForPartner/></div>
        );
      case 24:
        return (
          <div className="step step26">  <PhotoUpload/></div>
        );
      case 25:
        return (
          <div className="step step27">  <CreateCatchyHeading/></div>
        );
      case 26:
        return (
          <div className="step step28"> <FewWords/></div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 signUpmain p-16">
          <Box className="signUpTextdv d-flex justify-content-between align-items-stretch flex-column">
            <div className="">
              <div className="d-flex justify-content-between align-items-center py-16">
                <div className="Logo">
                  <Link>
                    <img
                      src="/assets/images/signup/logo-signup.svg"
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="font-16 fw-medium pb-24">
                  Already have an account? <Link className="text-gold-light">Log In</Link>
                </div>
              </div>
              <div className="maxw602">
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="font-m font-16 text-dark bg-transparent border-0"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <div className="fw-medium font-32 font-m">Signup</div>
                  <button
                    className="font-m font-16 text-dark bg-transparent border-0"
                    disabled
                  >
                    Next
                  </button>
                </div>
                <Box
                  sx={{
                    marginY: 2,
                    height: 5,
                    background: '#ddd',
                    borderRadius: 2,
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    className="linecustmSet"
                    sx={{
                      width: `${(currentStep / 26) * 100}%`,
                      height: '100%',
                    }}
                  ><span className="bgsignline"></span></Box>
                </Box>
              </div>
            </div>

            <Box className="maxw602 h-100">{renderStepContent(currentStep)}</Box>


            <div className="maxw602 bg-white text-center mt-32" style={{ position: 'sticky', bottom: '0' }}>
              <div className="mt-16 mb-0 text-center">
                {([6, 15, 17, 18, 19, 20,25,27,28].includes(currentStep)) && (
                  <button
                    className="border-0 text-decoration-underline bg-white font-16 font-m fw-medium skipbtn"
                    onClick={handleContinue} // Skip action: move to the next step
                  >
                    Skip
                  </button>
                )}
              </div>
              <div className="pb-16">
                <small className="font-m fst-italic fw-medium color5f">
                  {stepTexts[currentStep]}
                </small>
              </div>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    backgroundColor: '#916008',
                    padding: '10px',
                    color: '#fff',
                    '&:hover': { backgroundColor: '#9d6f3b' },
                  }}
                  onClick={handleContinue}
                >
                  {currentStep === 28 ? 'Finish' : 'Continue'}
                </Button>
              </Box>
            </div>

          </Box>

          <div className="rightImgSignup">
            <img
              src="/assets/images/how-luxury-life-works-bg.png"
              className="img-fluid"
              alt="background"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUpContainer;