import React, { useState, useEffect } from "react";

const EmailVerification = ({title, subtitle,handleContinue}) => {
    const [email, setEmail] = useState("graphics.faizal@gmail.com");
    const [code, setCode] = useState(["", "", "", ""]);
    const [timer, setTimer] = useState(60);
    const [message, setMessage] = useState({ type: "", text: "" });
    const [showImage, setShowImage] = useState(false);

    useEffect(() => {
        const firstEmptyIndex = code.findIndex((digit) => digit === "");
        if (firstEmptyIndex !== -1) {
            document.getElementById(`code-input-${firstEmptyIndex}`).focus();
        }
    }, [code]);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(countdown);
    }, []);

    const handleCodeChange = (value, index) => {
        const newCode = [...code];
        newCode[index] = value.slice(-1);
        setCode(newCode);

        if (newCode.every((digit) => digit !== "")) {
            validateCode(newCode);
        }

        if (value && index < code.length - 1) {
            document.getElementById(`code-input-${index + 1}`).focus();
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && code[index] === "") {
            if (index > 0) {
                document.getElementById(`code-input-${index - 1}`).focus();
            }
        }
    };

    const validateCode = (currentCode) => {
        if (currentCode.join("") === "1234") {
            setMessage({ type: "success", text: "OTP Verified" });
            setShowImage(true);
        } else {
            setMessage({ type: "error", text: "Oops! The Code You Entered is Incorrect." });
            setShowImage(false);
        }
    };

    const handleResendCode = () => {
        setTimer(60);
        setCode(["", "", "", ""]);
        setMessage({ type: "", text: "" });
        setShowImage(false);
    };

    const getInputStyle = (index) => {
        if (message.type === "success" && code.join("") === "1234") {
            return {
                border: "1px solid #BF8500",
                color: "#BF8500",
                background: "rgba(191, 131,0, 0.08)",
            };
        }
        if (message.type === "error" && code.join("") !== "1234") {
            return {
                border: "1px solid #D7443E",
                color: "#D7443E",
            };
        }
        return {
            border: "1px solid #ccc",
            color: "black",
        };
    };

    return (
        <div className="mw-400-px mx-auto btnsWithConnects text-center">

<div className="">
{/* Verify Your Email */}
{/* Enter the verification code sent to your email to proceed. */}
     <div className="font-m font-32 fw-medium text-center">{title}</div>
      <div className="font-m font-14 colorf5 pt-6 text-center">
      {subtitle}
      </div>
     </div>

     <div className="VerifyEmailFontSet"> 
                    <p className="font-m font-18 fw-normal mb-0">{email}</p>
                    <button
                        className="font-m font-14 f14-nvw"
                        style={{
                            background: "none",
                            border: "none",
                            color: "#916008",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={() => alert("Edit email functionality goes here.")}
                    >
                        Edit
                    </button>
                </div>
        
                <div className="">
                    <div className="font-m font-14 fw-normal" style={{ color: "#3C4043", padding: '1vw 0 0.5vw 0' }}>
                        This code will expire in{" "}
                        <span className="d-block font-m font-34 fw-bold" style={{ color: "#000" }}>
                            {`00:${timer < 10 ? `0${timer}` : timer}`}
                        </span>
                    </div>
                    <form className="VerifyEmailFontSet"
                        onSubmit={(e) => e.preventDefault()}
                        style={{ display: "flex", justifyContent: "center", gap: "10px" }}
                    >
                        {code.map((digit, index) => (
                            <input className="f24-nvw text-center"
                                key={index}
                                id={`code-input-${index}`}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleCodeChange(e.target.value, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                style={{
                                    width: "88px",
                                    height: "68px",
                                    textAlign: "center",
                                    borderRadius: "15px",
                                    ...getInputStyle(index),
                                    transition: "all 0.3s ease",
                                }}
                                autoFocus={index === 0}
                            />
                        ))}
                    </form>
                    <div className="VerifyEmailFontSet" style={{ paddingTop: '1vw' }}>
                        {code.every((digit) => digit !== "") && message.text && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "10px",
                                    color: message.type === "success" ? "green" : "red",
                                }}
                            >
                                {showImage && message.type === "success" && (
                                    <span className="d-block">
                                        <img
                                            src="/assets/images/signup/otp-verified.svg"
                                            className="imf-fluid"
                                            alt=""
                                        />
                                    </span>
                                )}
                                <div className="font-m font-14 f14-mvw">{message.text}</div>
                            </div>
                        )}
                        <button
                            className="font-m font-14 f14-mvw"
                            onClick={handleResendCode}
                            style={{
                                marginTop: "10px",
                                background: "none",
                                border: "none",
                                color: "#000",
                                textDecoration: "underline",
                                cursor: "pointer",
                                display: message.type === "success" ? "none" : "inline",
                            }}
                        >
                            Resend Code
                        </button>
                    </div>
                </div>
            </div> 
    );
};

export default EmailVerification;
