import { put, call } from 'redux-saga/effects';
import { signupCreateAPI, signupInvoiceSearchAPI, signupSearchAPI, signupUpdateAPI } from 'src/api/SignupApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { SIGNUP_ACTIONS } from '../actions/SignupActions';
import { toast } from 'react-toastify';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(signupSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(signupSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {

            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }


        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(signupCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        }));
        toast("Vendor Created")

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}

function* update(signupId, payload) {
    try {
        yield call(signupUpdateAPI, {
            signupId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_SEARCH, search),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_UPSERT, upsert),
    takeFirstSagaUtil(SIGNUP_ACTIONS.SIGNUP_EDIT, edit),
];
