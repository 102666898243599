import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CreateYourPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const passwordsMatch = password === confirmPassword || confirmPassword === '';

  return (
    <div>
      <div>
        <div className="font-m font-32 fw-medium text-center">Create Your Password</div>
        <div className="font-m font-18 colorf5 pt-6 text-center">
          To ensure your account’s security, please choose a new password.
        </div>
        <p
          className="font-m font-14 pt-16 text-center fst-italic text-center"
          style={{ color: '#7B7B7B' }}
        >
          Your password must be at least 8 characters long and include a mix of letters, numbers, and special characters.
        </p>
      </div>
      <Box sx={{ width: '100%', maxWidth: 400, mx: 'auto' }}>
        {/* New Password Field */}
        <TextField
          placeholder="Enter your new password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            height: '44px',
            '& .MuiOutlinedInput-root': {
              height: '44px',
              borderRadius: '15px',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#916008',
                borderWidth: '1px',
              },
            },
          }}
        />
         {!passwordsMatch && confirmPassword !== '' && (
          <Typography color="error" variant="body2" sx={{ mt: 0, textAlign: 'start', fontSize: '12px', fontWeight: '400',fontStyle: 'italic', color:'#EA4335' }}>
         The password you have entered has already been used. Please try again,
          </Typography>
        )}
        {/* Confirm Password Field */}
        <TextField
          placeholder="Confirm your password"
          type="text" // Always show the text for confirm password
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          fullWidth
          variant="outlined"
          margin="normal"
          error={!passwordsMatch && confirmPassword !== ''}
          helperText={
            !passwordsMatch && confirmPassword !== '' ? 'Passwords do not match' : ''
          }
          sx={{
            height: '44px',
            '& .MuiOutlinedInput-root': {
              height: '44px',
              borderRadius: '15px',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#916008',
                borderWidth: '1px',
              },
            },
          }}
        /> 
      </Box>
    </div>
  );
};

export default CreateYourPassword;
