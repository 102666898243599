import React, { useState } from 'react';
import { Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import LoginForm from './LoginForm';
import Footer from '../../containers/home-container/layout/Footer';
import Modal from '@mui/material/Modal';
import LinksYourAccount from 'src/components/signup/LinksYourAccount';
import EmailVerification from 'src/components/signup/EmailVerification';
import CreateYourPassword from 'src/components/signup/CreateYourPassword';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '20px',
    p: 4,
};


const LogIn = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const stepTexts = {
        1: "",
        2: "Can’t Access Your Email?",
        3: "Can’t Access Your Email?",
    };



    const handleContinue = () => {
        if (currentStep < 9) setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 1) setCurrentStep((prevStep) => prevStep - 1);
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        console.log("Captcha value:", value); // Optional: Log the captcha value
    };


    // se

    const [questions] = useState([
        "What is your favorite color?",
        "What is your mother's maiden name?",
        "What was your first pet's name?",
        "What is your hometown?",
        "What was your first car?"
    ]);

    const [answers, setAnswers] = useState({
        question1: "",
        answer1: "",

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAnswers({ ...answers, [name]: value });
    };


    const buttonTexts = {
        1: "Continue",
        2: "Send Request",
        3: "Continue",
        4: "Confirm",
        5: "Back to Log In",
        6: "Continue",
        7: "Continue",
        8: "Confirm",
        9: "Back to Log In",
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return (
                    <div className="step step1">
                        <div className="">
                            <div className="font-p font-36 fw-bold text-center text-gold-light fst-italic">Welcome to Luxury Life! </div>
                            <div className="font-m font-16 colorf5 fw-medium text-black pt-0 text-center">
                                Where Ambition Meets Affluence
                            </div>
                        </div>
                        <div className="mw-400-px mx-auto">
                            <p className="font-m font-14 text-black pt-16 text-center">
                                Discover exclusivity and meaningful connections.
                            </p>
                            <LoginForm />
                            <div className="text-end w-100"><Link
                                className="font-14 fw-medium colorc3 font-m"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent default link behavior
                                    handleContinue();   // Move to the next step
                                }}
                            >
                                Forgot Password?
                            </Link>
                            </div>
                        </div>
                        <div className="LoginAccountLink">

                            <p className="font-m font-12 colorf5 pt-6 text-center mb-0">or continue with </p>
                            <LinksYourAccount
                                firstName="Hisham"
                                lastName="Sabur"
                                email="hishamsabur@gmail.com"
                                avatar=""
                            />
                            {/* Add reCAPTCHA */}
                            <ReCAPTCHA className="mx-auto text-center d-flex justify-content-center mb-32"
                                sitekey="YOUR_SITE_KEY" // Replace with your reCAPTCHA site key
                                onChange={handleCaptchaChange}
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="step step2">
                        <div className="">
                            <div className="font-m font-32 fw-medium text-center">Reset Your Password</div>
                            <div className="font-m font-14 colorf5 pt-6 text-center fw-medium">
                                Forgot your password? Don’t worry – it happens!
                            </div>
                            <p className="font-m font-14 text-black pt-16 text-center fw-normal">Enter your registered email ID below, and we’ll send you a secure link to reset your password.</p>
                            <div className="icon-field my-32 w-100 mw-400-px mx-auto">
                                <span className="icon">
                                    <MailOutlineIcon />
                                </span>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control input-h-44"
                                    placeholder="Enter Email"
                                />
                            </div>
                            <p className="font-m font-16 text-black pt-16 text-center fw-medium">Need help? Contact our <Link className="text-black">support team</Link> for assistance. </p>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="step step3 text-center">
                        <EmailVerification title="Verify Your Email" subtitle="Enter the verification code sent to your email to proceed." />
                        <div className="text-center"><button className="font-12 color7b font-m fw-normal my-16 border-0 text-decoration-underline" style={{ background: 'none' }} onClick={handleOpen}>Troubleshoot</button></div>
                    </div>
                );
            case 4:
                return (
                    <div className="step step3 text-center">
                        <CreateYourPassword />
                    </div>
                );
            case 5:
                return (
                    <div className="step step3 text-center my-32">
                        <div className="mb-24"><img src="/assets/images/signup/otp-verified.svg" className="img-fluid" alt="" /></div>
                        <div className="font-32 font-m fw-medium mw-300-px mx-auto">Your Password has been changed</div>
                    </div>
                );
            case 6:
                return (
                    <div className="step step3 text-center my-32">
                        <div className="">
                            <div className="font-m font-32 fw-medium text-center">Verify Your Identity with Security Questions </div>
                            <div className="font-m font-18 colorf5 pt-6 text-center fw-normal">
                                Answer the security questions below as an alternate verification method if you can’t access your email.
                            </div>
                            <div className="icon-field my-32 w-100 mw-400-px mx-auto">
                                <span className="icon">
                                    <MailOutlineIcon />
                                </span>
                                <input
                                    type="email"
                                    name="email"
                                    className="form-control input-h-44"
                                    placeholder="Enter Email"
                                />
                            </div>
                        </div>
                    </div>
                );
            case 7:
                return (
                    <div className="step step3 text-center my-32">
                        <div className="security-setup-container mw-570-px mx-auto btnsWithConnects">
                            <div className="">
                                <div className="font-m font-32 fw-medium text-center">Security Questions </div>
                                <div className="font-m font-14 colorf5 pt-6 text-center">
                                    Please answer the security question you selected during account setup to verify your identity.
                                </div>
                            </div>


                            <form className="security-form pt-24">
                                <div className="font-20 font-m fw-medium mb-16 text-center">Choose One of The Following</div>
                                {[1].map((num) => (
                                    <div className="security-row d-block gap-3" key={num}>
                                        <select className="form-select mb-10 w-100"
                                            name={`question${num}`}
                                            value={answers[`question${num}`]}
                                            onChange={handleChange}
                                            required
                                            style={{ borderRadius: '15px' }}
                                        >
                                            <option value="">Select Question {num}</option>
                                            {questions.map((question, index) => (
                                                <option key={index} value={question}>
                                                    {question}
                                                </option>
                                            ))}
                                        </select>
                                        <input className="form-control mb-10"
                                            type="text"
                                            name={`answer${num}`}
                                            placeholder="Type your answer here"
                                            value={answers[`answer${num}`]}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                ))}
                            </form>
                        </div>
                    </div>
                );
            case 8:
                return (
                    <div className="step step3 text-center">
                        <CreateYourPassword />
                    </div>
                );
            case 9:
                return (
                    <div className="step step9 text-center my-32">
                        <div className="mb-24"><img src="/assets/images/signup/otp-verified.svg" className="img-fluid" alt="" /></div>
                        <div className="font-32 font-m fw-medium mw-300-px mx-auto">Your Password has been changed</div>
                    </div>
                );
            default:
                return null;
        }
    };


    const steps = [
        {
            number: 1,
            title: "Check Your Spam/Junk Folder",
            description: "Sometimes, our emails can end up in your spam folder. Make sure to check there!",
        },
        {
            number: 2,
            title: "Resend the Reset Link",
            description: "If you still haven’t received the email, click below to resend the password reset link to your inbox.",
            linkText: "Resend Link",
            link: "Resend Link",
        },
        {
            number: 3,
            title: "Contact Support",
            description: "If you're still experiencing issues, feel free to contact our support team for further assistance.",
            linkText: "support@luxurylife.ai",
            link: "support@luxurylife.ai2",
        },
    ];

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 signUpmain p-16">
                    <Box className="signUpTextdv d-flex justify-content-between align-items-stretch flex-column">
                        <div className="">
                            <div className="d-flex justify-content-between align-items-center py-16">
                                <div className="Logo">
                                    <Link>
                                        <img
                                            src="/assets/images/signup/logo-signup.svg"
                                            className="img-fluid"
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className="font-16 fw-medium pb-24">
                                    Don't have an account?   <Link className="text-gold-light">Join today.</Link>
                                </div>
                            </div>
                            <div className="maxw602">
                                <div className="d-flex justify-content-between align-items-center">
                                    <button
                                        className="font-m font-16 text-dark bg-transparent border-0"
                                        onClick={handleBack}
                                    >
                                        Back
                                    </button>

                                </div>
                            </div>
                        </div>

                        <Box className="maxw602 h-100">{renderStepContent(currentStep)}</Box>

                        <div className="maxw602 bg-white text-center mt-32" style={{ position: 'sticky', bottom: '0' }}>
                            <div className="mt-16 mb-0 text-center">
                                {([].includes(currentStep)) && (
                                    <button
                                        className="border-0 text-decoration-underline bg-white font-16 font-m fw-medium skipbtn"
                                        onClick={handleContinue} // Skip action: move to the next step
                                    >
                                        Skip
                                    </button>
                                )}
                                {([2, 3].includes(currentStep)) && (
                                    <div
                                        className="border-0 bg-white font-16 font-m fw-normal login"
                                    >
                                        Back to{" "}
                                        <Link
                                            style={{ color: '#916008', fontWeight: 500 }}
                                            onClick={(e) => {
                                                e.preventDefault(); // Prevent the default link behavior
                                                setCurrentStep(1);  // Set the currentStep to 1 (first step)
                                            }}
                                        >
                                            Log in
                                        </Link>
                                    </div>
                                )}


                            </div>
                            <div className="pb-16">
                                <small className="font-m fst-italic fw-medium color5f">
                                    {stepTexts[currentStep]}{" "}
                                    {[2, 3].includes(currentStep) && (
                                        <Link
                                            to="/some-path" // Replace with the path you want to navigate to
                                            style={{ color: '#000', fontWeight: 500 }}
                                        >
                                            Try Another Way
                                        </Link>
                                    )}

                                </small>
                            </div>
                            <Box>
                                {currentStep === 1 ? (
                                    <Link to="/profilecontainer" style={{ textDecoration: 'none', textTransform: 'capitalize' }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: '100%', textTransform: 'capitalize',
                                                backgroundColor: '#916008',
                                                padding: '10px',
                                                color: '#fff',
                                                '&:hover': { backgroundColor: '#9d6f3b' },
                                            }}
                                        >
                                            Continue
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            width: '100%',
                                            backgroundColor: '#916008',
                                            padding: '10px',
                                            color: '#fff', textTransform: 'capitalize',
                                            '&:hover': { backgroundColor: '#9d6f3b' },
                                        }}
                                        onClick={handleContinue}
                                    >
                                        {buttonTexts[currentStep] || 'Continue'}
                                    </Button>
                                )}
                            </Box>
                        </div>
                    </Box>
                    <div className="rightImgSignup">
                        <img
                            src="/assets/images/how-luxury-life-works-bg.png"
                            className="img-fluid"
                            alt="background"
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        ...style,
                        maxHeight: '80vh', // Adjust as needed
                        overflowY: 'auto', // Enables vertical scroll
                    }}
                >
                    <Box className="d-flex justify-content-between align-items-start">
                        <div
                            className="modal-title font-32 fw-medium text-black"
                            id="exampleModalLabel"
                        >
                            Trouble Resetting Your Password?
                        </div>
                        <img
                            src="/assets/images/login/trouble.svg"
                            className="img-fluid"
                            alt=""
                        />
                    </Box>
                    <p className="modal-title font-18 fw-normal text-black">
                        If you didn’t receive the password reset link or are having trouble, try
                        one of the following options:
                    </p>
                    <List>
                        {steps.map((step) => (
                            <ListItem key={step.number} sx={{ paddingLeft: '0' }}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            background: '#faf5eb',
                                            color: '#916008',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {step.number}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={step.title}
                                    secondary={
                                        <>
                                            <span>{step.description}</span>
                                            {step.link && (
                                                <a
                                                    href={step.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        color: '#916008',
                                                        fontSize: '12px',
                                                        textDecoration: 'underline',
                                                        display: 'block',
                                                        marginTop: '4px',
                                                    }}
                                                >
                                                    {step.linkText}
                                                </a>
                                            )}
                                        </>
                                    }
                                    sx={{
                                        '& .MuiListItemText-primary': {
                                            color: '#3C4043',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Neue Montreal',
                                        },
                                        '& .MuiListItemText-secondary': {
                                            color: '#3C4043',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontFamily: 'Neue Montreal',
                                        },
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <div className="popup-actions text-center">
                        <Link className="fw-medium font-m font-16 text-black bg-none border-0 text p-10 mb-16 d-inline-block">
                            Try Another Way
                        </Link>
                        <Button
                            variant="contained"
                            sx={{
                                width: '100%', position: 'sticky', bottom: '0',
                                backgroundColor: '#916008',
                                padding: '10px',
                                color: '#fff',
                                textTransform: 'capitalize',
                                '&:hover': { backgroundColor: '#9d6f3b' },
                            }}
                            onClick={handleContinue}
                        >
                            Complete Verification
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default LogIn;