import { Container } from '@mui/material'
import React from 'react'
import YourPrivacy from '../YourPrivacy'
import YourPrivacyRight from '../YourPrivacy/YourPrivacyRight'

const YourPrivacySec = () => {
    const phoneImage = '/assets/images/phonescreen.png';
    const textImage = '/assets/images/phonescreentext.png';
  return (
   
       <div className="bgshadowsetLeft YourPrivacy-Sec" style={{ background: '#000' }}> 
        <Container maxWidth="xl">
            <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
      <YourPrivacy />
      </div>
      <div className="col-md-6">
      <YourPrivacyRight phoneImage={phoneImage} textImage={textImage} />
      </div>
      </div>
      </Container>
    </div>
    
  )
}

export default YourPrivacySec
