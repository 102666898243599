import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import { Route } from 'react-router-dom';
import { setDefaultHeadersActions } from '../app-container/actions/AppActions';

// import Permission from 'src/components/Permission';

// import Home from '../home-container/Home';
function MainContainer() {
  const dispatch = useDispatch();
  const userScopes = useSelector((state) => state.auth.user.userScopes, shallowEqual);
  if (userScopes && userScopes.length === 0) {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));
    setDefaultHeadersActions({
      Authorization: token,
    });
    // dispatch(fetchUserScopesAction({ userId: user?._id, roleId: user?.roleDetails?._id }));
  }
  return (
    <>
      {/* <LeftNavBar />
      <main className="dashboard-main">
        <HeaderNav />
        <div className="dashboard-main-body pt-0">
          <Routes>             
            <Route path="home" element={<TaskPendingContainer />} />
          </Routes>
        </div>
      </main> */}
    </>
  );
}

export default MainContainer;
