import React from 'react';
import Routings from './routes';

const App = () => {
  return (
    <>
      <Routings />
    </>
  );
};

export default App;
