
// const API = "http://localhost:8000"
// export default {
//   baseUrl: `${API}/api/v1/`,
//   imageUrl: "https://stagging.luxurylife.ai/uploads/",
// };


const API = "https://server.luxurylife.ai/"
export default {
  baseUrl: `${API}/api/v1/`,
  imageUrl: "https://stagging.luxurylife.ai/uploads/",
};
