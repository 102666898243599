import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '20px',
  p: 4,
};

const YourLocation = () => {
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleUseCurrentLocation = () => {
    setLoading(true);
    setErrorMessage('');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            // Fetch location details using Google Maps Geocoding API
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=YOUR_GOOGLE_API_KEY`
            );
            const data = await response.json();

            if (data.status === 'OK') {
              const addressComponents = data.results[0].address_components;

              // Extract country, state, and city from address components
              const country = addressComponents.find((component) =>
                component.types.includes('country')
              )?.long_name;
              const state = addressComponents.find((component) =>
                component.types.includes('administrative_area_level_1')
              )?.long_name;
              const city = addressComponents.find((component) =>
                component.types.includes('locality')
              )?.long_name;

              setLocation(`${city}, ${state}, ${country}`); // Update input value
            } else {
              setErrorMessage(
                <>
                  <div>Unable to fetch geolocation</div>
                  <div>Please try again or enter the details.</div>
                </>
              );
            }
          } catch (error) {
            console.error('Error fetching location:', error);
            setErrorMessage('An error occurred while fetching location.');
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error fetching location:', error);
          setErrorMessage('Unable to access your location.');
          setLoading(false);
        }
      );
    } else {
      setErrorMessage('Geolocation is not supported by your browser.');
      setLoading(false);
    }
  };



  return (
    <div className="mw-425-px btnsWithConnects mx-auto">
      <div className="">
        <div className="font-m font-32 fw-medium text-center">Where Are You Located?</div>
        <div className="font-m font-14 colorf5 pt-6 text-center">
          Your location helps us find the best matches near you.
        </div>
      </div>

      <form className="navbar-search mt-32">
        <TextField
          type="text"
          name="search"
          placeholder="Search"
          variant="outlined"
          fullWidth
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            height: '44px',
            '& .MuiOutlinedInput-root': {
              height: '44px',
              borderRadius: '15px',
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#916008',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#916008',
                borderWidth: '1px',
              },
            },
          }}
        />
      </form>

      <button
        className="CurrentLocation bg-white border-0 font-18 font-m fw-medium my-28"
        onClick={handleOpen}
      >
        <MyLocationIcon /> Use My Current Location
      </button>

      {loading && (
        <div className="loading-state flex-column gap-2">
          <div className="loading22"></div>
          <span className="d-block mt-6">Searching...</span>
        </div>
      )}

      {errorMessage && <div className="error-message text-center font-14 fw-normal font-m" style={{ color: '#DD4B39' }}>{errorMessage}</div>}


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mb: 4, fontSize: '32px', color: '#000', textAlign: 'center', fontWeight: '500', fontFamily: 'Neue Montreal' }}>
            Allow browser to access your location to set your location preference
          </Typography>
          <Box className="d-flex justify-content-between w-100 gap-3">
            <Button className="w-100 outLineBtn rounded-1 text-black">Block</Button>
            <Button
              className="w-100 fillBtn rounded-1 text-white"
              onClick={() => {
                handleClose(); // Close the modal
                handleUseCurrentLocation(); // Execute the use location logic
              }}
              disabled={loading}
              style={{
                cursor: loading ? 'not-allowed' : 'pointer',
                background: 'rgba(0, 0, 0, 0.70)',
              }}
            >
              Allow
            </Button>

          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default YourLocation