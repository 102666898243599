import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { SIGNUP_ACTIONS } from '../actions/SignupActions';
import { SignupDefaultProps } from '../SignupPropTypes';

const initialState = () => ({
    currentRecord: { ...SignupDefaultProps },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },
    invoiceSearch: {
        data: [],
        currentPage: 0,
        pages: 0,
        total: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case SIGNUP_ACTIONS.SIGNUP_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case SIGNUP_ACTIONS.SET_PAYLOAD: {
            return {
                ...state,
                currentRecord:{
                    ...state.currentRecord,
                    ...action.data,
                },
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_INVOICE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_INVOICE_SEARCH_SUCCESS: {
            return {
                ...state,
                invoiceSearch: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_INVOICE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case SIGNUP_ACTIONS.SIGNUP_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case SIGNUP_ACTIONS.SIGNUP_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case SIGNUP_ACTIONS.SIGNUP_RESET: {
            return {
                ...state,
                currentRecord: { ...SignupDefaultProps },
            };
        }

        default:
            return state;
    }
};
